import React, {useEffect, useRef, useState} from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import { Controller, useForm } from 'react-hook-form';
import generalStyles from '../../../../styles/general.module.css';
import * as Button from '../../../UI/Forms/Button';
import Modal from '../../../UI/Modal/Modal';
import Input from '../../../UI/Forms/Input';
import DatePicker from 'react-datepicker';
import Label from '../../../UI/Forms/Label';
import Select from 'react-select';
import Docs from './Docs';
import spmsServiceService from '../../../../services/spmsService.service';
import { useParams } from 'react-router-dom';
import Toast from '../../../UI/General/Toast';
import {paymentMethods} from "../../../../constants/paymentMethods";

const ModalLoadPayment = (props) => {
  const { orderId } = useParams();
  const [tab, setTab] = useState('Notes');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [invoiceOptions, setInvoiceOptions] = useState([])
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false)
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      linkedInvoice: null,
      paymentMethod: [],
      amount: '',
      reference: '',
      date: new Date(),
      effectivePayDate: '',
      batchReference: '',
      notes: '',
      files: [],
    },
  });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data) => {
    console.log(data);
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const { files, amount, paymentMethod, linkedInvoice, ...other } = data;
    let newFiles = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newFiles = await Promise.all(filesArray).then((result) => result);
    }
    if (!newFiles?.length) {
      setIsButtonsBlocked(false)
      setTab('Documents');
      requiredRef.current = true
      setError('files', { type: 'focus', message: 'Files required' });
      return;
    }
    const newData = {
      ...other,
      amount: parseFloat(amount).toFixed(2),
      paymentMethod: paymentMethod.value,
      attachments: newFiles,
      invoice: (linkedInvoice !== null) ? {
        id: linkedInvoice.value,
        invoiceNumber: linkedInvoice.label
      } : null
    };
    if (orderId) {
      await spmsServiceService
        .createPurchaseOrderPayment(orderId, newData)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Payment loaded successfully',
            type: 'success',
            cb: () => (props.$callback(), props.$trigger(state => state +1), props.$close()),
          });
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false)
          setToast({
            opened: true,
            message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
            type: 'fail',
          });
        });
    }
  };

  useEffect(() => {
    spmsServiceService.getPurchaseOrderInvoices(orderId).then((r) => {
      if (r.data.message === 'Operation Successful') {
        const options = r.data.data.filter(item => item.approvalStatus === "APPROVED").map(el => ({label: el.invoiceNumber, value: el.id}))
        setInvoiceOptions(options);
      }
    });
  },[])

  return (
    <>
      <Modal
        $show={props.$show}
        $close={props.$close}
        $title={"Load Payment (" + props?.poNumber + ")"}
        $radius={12}
        $maxWidth={'1200px'}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={styles.modal}>
          <div className={styles.modalFormInner}>
            <div className={generalStyles.fieldsThree}>
              <div className="inp-container">
                <Label $title="Linked Invoice"/>
                <Controller
                  name="linkedInvoice"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Invoice"
                      options={invoiceOptions}
                      isClearable={true}
                    />
                  )}
                />
                {errors.linkedInvoice && <p className="error-message">{errors.linkedInvoice.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="reference"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Payment Reference is Required',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum 50 characters',
                    },
                    validate: {
                      allowed: (v) =>
                        /^[-&()0-9A-Za-z\s]*$/.test(v) ||
                        'Alphabetic, numeric, &()- characters and space only',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Payment Reference"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Payment Reference"
                      $labelRequired
                      {...field}
                    />
                  )}
                />
                {errors.reference && <p className="error-message">{errors.reference.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="effectivePayDate"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Effective Pay Date is required',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Enter Effective Pay Date"
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        customInput={
                          <Input
                            $label="Effective Pay Date"
                            $labelRequired
                            className={errors.hasOwnProperty(field.name) && 'error'}
                          />
                        }
                      />
                    );
                  }}
                />
                {errors.effectivePayDate && (
                  <p className="error-message">{errors.effectivePayDate.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Payment Method" $isRequired/>
                <Controller
                  name="paymentMethod"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Payment Method is required',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Payment Method"
                      options={paymentMethods || []}
                    />
                  )}
                />
                {errors.paymentMethod && (
                  <p className="error-message">{errors.paymentMethod.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Controller
                  name="batchReference"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Batch Reference is required',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Batch Reference"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Batch Reference"
                      $labelRequired
                      {...field}
                    />
                  )}
                />
                {errors.batchReference && (
                  <p className="error-message">{errors.batchReference.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Controller
                  name="amount"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Amount is required',
                    },
                    maxLength: {
                      value: 10,
                      message: "Maximum 10 characters"
                    },
                    min: {
                      value: 0.01,
                      message: "Min 0.01"
                    },
                    validate: {
                      allowed: v => /^[0-9]+([.][0-9]{1,2})?$/.test(v) || 'Max depth 0.00',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Amount"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Amount"
                      $labelRequired
                      {...field}
                    />
                  )}
                />
                {errors.amount && <p className="error-message">{errors.amount.message}</p>}
              </div>
            </div>
            <Docs
              errors={errors}
              control={control}
              nameNotes={'notes'}
              nameFiles={'files'}
              labelNotes={'Notes'}
              labelFiles={'Documents'}
              tab={tab}
              setTab={setTab}
              requiredRef={requiredRef}
              clearErrors={clearErrors}
              showInteraction={true}
            />
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid || isButtonsBlocked}>
              Save
            </Button.Main>
            <Button.Main $primary $style="gray" onClick={props.$close} type="button">
              Discard
            </Button.Main>
          </div>
        </form>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default ModalLoadPayment;
