import React, { useMemo, useState } from 'react';
import Box from '../../UI/General/Box';
import styles from '../../../styles/systemRolesPermissions.module.css';
import generalStyles from '../../../styles/general.module.css';
import { Controller } from 'react-hook-form';
import Input from '../../UI/Forms/Input';
import Label from '../../UI/Forms/Label';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import DataTableBase from '../../UI/General/DataTableBase';
import Icon from '../../UI/General/Icon';
import * as Button from '../../UI/Forms/Button.jsx';
import Text from '../../UI/Typography/Text.jsx';
import UserLegalEntity from './UserLegalEntity.jsx';
import Dropdown from '../../UI/General/Dropdown.jsx';
import { Menu, MenuItem } from '../../UI/General/Menu.jsx';
import {regExps} from "../../../utils/regExps";

const UserAccount = ({
  control,
  errors,
  roles,
  companies,
  setLinkedCompanies,
  linkedCompanies,
  setValue,
  userId,
  isUserInvited,
}) => {
  const [mode, setMode] = useState(false);
  const [editData, setEditData] = useState(null);

  const columns = useMemo(() => [
    {
      name: 'Company Name',
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: 'LOA',
      selector: (row) => row.limitOfAuthority,
      sortable: true,
    },
    {
      name: 'Branch',
      selector: (row) => row.branch?.name,
      sortable: true,
    },
    {
      name: 'Department',
      selector: (row) => row.department?.name,
      sortable: true,
    },
    {
      name: 'Roles',
      selector: (row) => row.roles.map((role) => role.label).join(', '),
      sortable: true,
      cell: (row) => (
        <div className={styles.roles}>
          {row.roles && row.roles.map((role) => <span>{role.label}</span>)}
        </div>
      ),
    },
    {
      name: 'Action',
      allowOverflow: true,
      button: true,
      omit: isUserInvited,
      cell: (row) => (
        <Dropdown collapsible className={generalStyles.actionMenu}>
          <Dropdown.Header>
            <Button.Action type="button" $style="white" $width={32} $height={32}>
              <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
            </Button.Action>
          </Dropdown.Header>
          <Dropdown.Body>
            <Menu className={generalStyles.actionMenuList}>
              <MenuItem onClick={() => editCompany(row)}>Edit</MenuItem>
              <MenuItem onClick={() => removeCompany(row.companyId)}>Remove</MenuItem>
            </Menu>
          </Dropdown.Body>
        </Dropdown>
      ),
    },
  ]);

  const editCompany = (row) => {
    setMode(true);
    setEditData(row);
  };

  const removeCompany = (id) => {
    setLinkedCompanies((companies) => companies.filter((company) => company.companyId !== id));
  };

  return (
    <Box $radius={12} className={styles.formInner} $noOverflow>
      <div className={generalStyles.fieldsThree}>
        <div className="inp-container">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'First Name is required',
              },
              maxLength: {
                value: 50,
                message: 'Maximum 50 characters',
              },
              validate: {
                allowed: (v) =>
                  /^[-A-Za-z\s]*$/.test(v) || 'Alphabetic characters, - and spaces only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                disabled={isUserInvited}
                placeholder="Enter First Name"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="First Name"
                $labelRequired
                $tooltip="First Name of the user"
                {...field}
              />
            )}
          />
          {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Last Name is required',
              },
              maxLength: {
                value: 50,
                message: 'Maximum 50 characters',
              },
              validate: {
                allowed: (v) => /^[A-Za-z\s]*$/.test(v) || 'Alphabetic characters and space only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                disabled={isUserInvited}
                type="text"
                placeholder="Enter Last Name"
                $labelRequired
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Last Name"
                $tooltip="Last Name of the user"
                {...field}
              />
            )}
          />
          {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="position"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Job Description is required *',
              },
              maxLength: {
                value: 30,
                message: 'Maximum 30 characters',
              },
              validate: {
                allowed: (v) => /^[A-Za-z\s]*$/.test(v) || 'Alphabetic characters and space only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter Job Description"
                $labelRequired
                disabled={isUserInvited}
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Job Description"
                $tooltip="Job Description of the user"
                {...field}
              />
            )}
          />
          {errors.position && <p className="error-message">{errors.position.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Email Address is required',
              },
              pattern: {
                value: regExps.email,
                // value: /^[\w]*@*[A-Za-z]*\.*[\w]{3,}(\.)*(@yopmail\.com|@gmail\.com|@yahoo\.com|@bing\.com)$/g,
                message: 'Email is not valid',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter Email Address"
                $labelRequired
                disabled={!!userId || isUserInvited}
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Email Address"
                $tooltip="Email of the user"
                {...field}
              />
            )}
          />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <div className="inp-container">
          <Label $title="Phone Number" $isRequired $tooltipText="Phone Number of the user" />
          <Controller
            name="phoneNumber"
            rules={{
              required: true,
              validate: {
                isValid: (value) =>
                  isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || ''),
              },
            }}
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                placeholder="Enter Phone Number"
                limitMaxLength={true}
                international={true}
                disabled={isUserInvited}
                className={errors.hasOwnProperty(field.name) ? 'error' : ''}
              />
            )}
          />
          {errors.phoneNumber?.type === 'required' && (
            <p className="error-message">Phone number is required</p>
          )}
          {errors.phoneNumber?.type === 'isValid' && (
            <p className="error-message">Enter a valid phone number</p>
          )}
        </div>
      </div>
      <div>
        <div className={styles.linkCompanyButton}>
          <div>
            <Text type="h3" weight={600}>Companies</Text>
          </div>
          {!isUserInvited && (
            <Button.ActionLabeled onClick={() => setMode(true)}>
              <Button.Action $style="pink" $variant="circle" $width={20} $height={20} type="button">
                <Icon $width={20} $height={20} $icon="plus" $color="white" />
              </Button.Action>
              <Text weight={700} type="subtitle">Link Company</Text>
            </Button.ActionLabeled>
          )}
        </div>
        {mode && (
          <UserLegalEntity
            roles={roles}
            editData={editData}
            setMode={setMode}
            companies={companies}
            setLinkedCompanies={setLinkedCompanies}
            setEditData={setEditData}
            linkedCompanies={linkedCompanies}
            setParrentValue={setValue}
          />
        )}
        {mode && !linkedCompanies.length ? null : (
          <DataTableBase
            data={linkedCompanies}
            columns={columns}
            paginationResetDefaultPage={false}
          />
        )}
      </div>
    </Box>
  );
};

export default UserAccount;
