import spmsServiceService from '../services/spmsService.service';

export const companySlice = (set) => ({
  company: null,
  activeCompany: null,
  fullCompanies: [],
  updateCompany: (data) => set({ company: data }),
  setActiveCompany: async (companyId) => {
    await spmsServiceService.getCompany(companyId).then((res) => {
        set({ activeCompany: res.data.data });
      }).catch((err) => {
        console.log('Error fetching company details');
      });
  },
  setFullCompanies: (data) => set({ fullCompanies: data }),
  setCompany: async (companyId) => {
    await spmsServiceService.getCompany(companyId).then((res) => {
        set({ company: res.data.data });
      }).catch((err) => {
        console.log('Error fetching company details');
      });
  },
  subscriptionChanged: false,
  setSubscriptionChanged: (data) => set({subscriptionChanged: data}),
});
