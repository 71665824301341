import React, { useEffect, useState } from 'react';
import style from '../../../styles/users.module.css';
import Box from '../../UI/General/Box';
import generalStyles from '../../../styles/general.module.css';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../UI/Forms/Input';
import Label from '../../UI/Forms/Label';
import Select from 'react-select';
import MultiSelectOpened from '../../UI/Forms/MultiSelectOpened';
import spmsService from '../../../services/spmsService.service';
import * as Button from '../../UI/Forms/Button.jsx';

const UserLegalEntity = ({
  roles,
  editData,
  setMode,
  companies,
  setLinkedCompanies,
  setEditData,
  linkedCompanies,
  setParrentValue,
}) => {
  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, isValid },
    setValue,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const selectedCompany = watch('companyId');

  useEffect(() => {
    const fetchBranchesDepartments = async () => {
      setIsLoading(true);
      await Promise.all([
        spmsService
          .getBranches(selectedCompany?.value)
          .then((r) => {
            if (r.data.status === 'success') {
              if (r.data.data !== undefined && r.data.data.length > 0) {
                const activeBranches = r.data.data.filter((branch) => branch.active);
                const branches = activeBranches.map((item) => ({
                  label: item.name,
                  value: item.id,
                }));
                setBranches(branches);
              } else setBranches([]);
            }
          })
          .catch((err) => {
            setBranches([]);
          }),
        spmsService.getDepartments(selectedCompany?.value).then((r) => {
          if (r.data.status === 'success') {
            if (r.data.data !== undefined && r.data.data.length > 0) {
              const activeDepartments = r.data.data.filter((department) => department.active);
              const departments = activeDepartments.map((item) => ({
                label: item.name,
                value: item.id,
              }));
              setDepartments(departments);
            } else setDepartments([]);
          }
        }),
      ]).then((_res) => setIsLoading(false));
    };
    if (selectedCompany?.value) {
      fetchBranchesDepartments();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (editData) {
      setValue(
        'companyId',
        companies.find((company) => editData.companyId === company.value),
      );
    }
  }, [editData]);

  useEffect(() => {
    if (!editData || !branches.length || !departments.length) return;
    setValue(
      'branches',
      branches.find((branch) => editData.branchId === branch.value),
    );
    setValue(
      'departments',
      departments.find((department) => editData.departmentId === department.value),
    );
    setValue('limitOfAuthority', editData.limitOfAuthority);
    const roleIDs = editData.roles ? editData.roles.map((role) => role.value) : [];
    setValue(
      'roles',
      roles.filter((role) => roleIDs.includes(role.value)),
    );
  }, [editData, branches, departments]);

  const linkCompany = (data) => {
    const newCompany = {
      companyName: data.companyId.label,
      companyId: data.companyId.value,
      limitOfAuthority: data.limitOfAuthority,
      branch: {
        name: data.branches.label,
        id: data.branches.value,
      },
      department: {
        name: data.departments.label,
        id: data.departments.value,
      },
      roles: data.roles,
    };
    if (editData) {
      setLinkedCompanies((companies) => {
        const filteredCompanies = companies.filter(
          (company) => company.companyId !== editData.companyId,
        );
        return [...filteredCompanies, newCompany];
      });
    } else {
      if (linkedCompanies.find((company) => company.companyId === data.companyId.value)) {
        setError('companyId', {
          type: 'pattern',
          message: 'Cannot link the same company twice',
        });
        return;
      }
      setLinkedCompanies((companies) => [...companies, newCompany]);
    }
    setParrentValue('companiesChanged', true);
    setMode(false);
    setEditData(null);
  };
  return (
    <Box $radius={12} className={style.linkCompany}>
      <div className={style.fields}>
        <div className={style.inputs}>
          <div className={style.leftSection}>
            <div className={generalStyles.fieldsTwo}>
              <div className="inp-container">
                <Label $title="Company" $isRequired $tooltipText="Company the user is linked to" />
                <Controller
                  name="companyId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Company is required',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Company *"
                      options={companies}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue('branches', null);
                        setValue('departments', null);
                      }}
                    />
                  )}
                />
                {errors.companyId && <p className="error-message">{errors.companyId.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="limitOfAuthority"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: 'Limit of Authority is required',
                    },
                    maxLength: {
                      value: 10,
                      message: 'Maximum 10 characters',
                    },
                    validate: {
                      allowed: (v) => /^[0-9]*$/.test(v) || 'Numerical values only',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Limit of Authority"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Limit of Authority"
                      $labelRequired
                      $tooltip="Amount the user is allowed to approve"
                      {...field}
                    />
                  )}
                />
                {errors?.limitOfAuthority && (
                  <p className="error-message">{errors?.limitOfAuthority?.message}</p>
                )}
              </div>
            </div>
            <div className={generalStyles.fieldsTwo}>
              <div className="inp-container">
                <Label $title="Branch" $isRequired $tooltipText="Branch the user is linked to" />
                <Controller
                  name="branches"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Branch is required',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Branch *"
                      options={branches}
                    />
                  )}
                />
                {errors.branches && <p className="error-message">{errors.branches.message}</p>}
              </div>
              <div className="inp-container">
                <Label $title="Department" $isRequired $tooltipText="Department the user is linked to" />
                <Controller
                  name="departments"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Department is required',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Department *"
                      options={departments}
                    />
                  )}
                />
                {errors.departments && (
                  <p className="error-message">{errors.departments.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className={style.rightSection}>
            <div className="inp-container">
              <Label $title="Roles" $isRequired $tooltipText="Roles the user is linked to" />
              <Controller
                name="roles"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Roles are required',
                  },
                }}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Roles"
                    options={roles}
                    control={control}
                    noSelectContainer
                  />
                )}
              />
              {errors.roles && <p className="error-message">{errors.roles.message}</p>}
            </div>
          </div>
        </div>

        {(!branches.length || !departments.length) && selectedCompany && !isLoading && (
          <p>
            The company <strong>{selectedCompany?.label}</strong> has no Branches or Departments you
            can't invite or edit user
          </p>
        )}
        <div className={generalStyles.pageButtons}>
          <Button.Main
            disabled={!isDirty || !isValid}
            $primary
            $style="pink"
            type="submit"
            onClick={handleSubmit(linkCompany)}
          >
            Save
          </Button.Main>
          <Button.Main
            $primary
            $style={!isDirty || !isValid ? 'pink' : 'gray'}
            onClick={() => {
              setMode(false);
              setEditData(null);
            }}
            type="button"
          >
            Discard
          </Button.Main>
        </div>
      </div>
    </Box>
  );
};

export default UserLegalEntity;
