import React, {useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import s2pms from "../../services/spmsService.service";
import Toast from "../../components/UI/General/Toast";
import {Controller, useForm} from "react-hook-form";
import Input from "../../components/UI/Forms/Input";
import generalStyles from "../../styles/general.module.css";
import styles from "../../styles/purchaseOrders.module.css";
import DatePicker from "react-datepicker";
import DataTableBase from "../../components/UI/General/DataTableBase";
import Docs from "../../components/Admins/PurchaseOrders/Modals/Docs";
import * as Button from "../../components/UI/Forms/Button";
import moment from "moment";
import Title from "../../components/Admins/PurchaseOrders/View/Title";
import Box from "../../components/UI/General/Box";
import Text from "../../components/UI/Typography/Text";
import Label from "../../components/UI/Forms/Label";

const ReceivedItems = () => {
  const {orderId, receivedItemId} = useParams();
  const user = useStore((state) => state.user);
  const [tab, setTab] = useState('Notes');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [values, setValues] = useState(null)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false)
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      files: [],
      receivedDate: '',
      receivedBy: null,
      notes: '',
      items: [],
    },
    values
  });

  const columns = [
    {
      name: 'Description',
      selector: row => row?.purchaseOrderItem?.description,
      grow: 3
    },
    {
      name: 'Quantity Pending',
      selector: row => row?.purchaseOrderItem?.quantity - row.receivedQuantity,
    },
    {
      name: 'Quantity Received',
      cell: (row, index) => {
        return row.fullfillmentStatus === 'FULFILLED' || values?.createdBy?.userId !== user?.id ? (
          row.receivedQuantity
        ) : (
          <>
            <Input
              $small
              {...register(`items.${index}.receivedQuantity`, {
                min: 0,
                validate: {
                  allowed: v => /\d+(\.\d+)?/g.test(v) || 'Numerical values only',
                  allowedDepth: v => /^[0-9]+([.][0-9]{1,3})?$/.test(v) || 'Max depth 0.000',
                },
              })}
              placeholder="0"
              className={errors?.items?.[index]?.receivedQuantity ? "error" : ""}
              defaultValue={0}
            />
            <input
              type="hidden"
              {...register(`items.${index}.purchaseOrderItemId`, { value: row.id })}
            />
          </>
        )
      }
    },
  ]

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  useEffect(() => {
    s2pms.getReceiveItemPurchaseOrder(orderId, receivedItemId).then(r => {
      if (r.data.message === "Operation Successful"){
        console.log(r.data.data)
        const {receivedItems, receivedDate, deliveryNotes, ...other} = r.data.data
        const data = {
          ...other,
          items: receivedItems,
          notes: deliveryNotes,
          receivedDate: new Date(receivedDate)
        }
        setValues(data)
      }
    }).catch(err => {
      console.log(err)
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    })
  },[])

  const handleFull = () => {
   values?.items?.forEach(el => {
      const iof = getValues('items').indexOf(getValues('items').find(gvi => gvi.id === el.id))
      setValue(`items.${iof}.receivedQuantity`, el.purchaseOrderItem.quantity, { shouldValidate: true, shouldDirty: true })
    })
  }

  const onSubmit = async (data) => {
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const { files } = data;
    let newAttachments = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newAttachments = await Promise.all(filesArray).then((result) => result);
    }
    data.items.map(el => {
      console.log(el)
    })
    const updItems = data.items.map((item) => ({
      ...item,
      purchaseOrderItemId: item.purchaseOrderItem.id,
      receivedQuantity: parseFloat(item.receivedQuantity)
    })).filter((item) => item.receivedQuantity > 0);
    console.log(updItems)
    const newData = {
      receivedDate: moment(data.receivedDate).toISOString(),
      receivedBy: {
        userId: user?.id
      },
      notes: data.notes,
      items: updItems,
      attachments: newAttachments,
    };
    console.log(newData)
    s2pms.updateReceiveItemPurchaseOrder(orderId, receivedItemId, newData).then((r) => {
      reset();
      setToast({
        opened: true,
        message: 'Received Items has been submitted successfully',
        type: 'success',
        cb: () => navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: "receiving" } }),
      });
    }).catch((reason) => {
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.errors[0].errorMessage,
        type: 'fail',
      });
    });
  };

  return (
    <>
      <Title
        title="Received Items"
        poState={values?.fullfillmentStatus}
      />
      <Box $mobExtend $asHolder>
        <form>
          <div className={generalStyles.fieldsThree}>
            {values?.createdBy?.userId !== user?.id ? (
              <div>
                <Label $title={"Received Date"}/>
                <Text type={'body-1'}>{moment(values?.receivedDate).format('YYYY-MM-DD')}</Text>
              </div>
            ) : (
              <div className="inp-container">
                <Controller
                  name="receivedDate"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Received Date is required',
                    },
                    maxLength: {
                      value: 10,
                      message: 'Maximum 10 characters',
                    },
                  }}
                  render={({field}) => (
                    <DatePicker
                      {...field}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      placeholderText="Select Date"
                      selected={field.value}
                      wrapperClassName="custom-datepicker"
                      customInput={
                        <Input
                          {...field}
                          $label="Received Date"
                          $labelRequired
                          $tooltip="The date the delivery was made"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                        />
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        trigger(field.name);
                      }}
                    />
                  )}
                />
                {errors.receivedDate && <p className="error-message">{errors.receivedDate?.message}</p>}
              </div>
            )}
          </div>
          <DataTableBase
            data={values?.items || []}
            columns={columns}
            pagination={false}
            className={styles.receivedItemsTable}
          />
          <Docs
            errors={errors}
            control={control}
            nameNotes={'notes'}
            labelNotes={'Notes'}
            nameFiles={'files'}
            labelFiles={'Documents'}
            tab={tab}
            setTab={setTab}
            requiredRef={requiredRef}
            clearErrors={clearErrors}
            attachments={values?.attachments}
            showInteraction={values?.createdBy?.userId === user?.id}
            notesList={values?.notesList ?? []}
          />
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        {values?.createdBy?.userId === user?.id ? (
          <>
            <Button.Main $primary $style="pink" onClick={() => handleFull()} type="button" disabled={isButtonsBlocked}>
              Receive in full
            </Button.Main>
            <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid || isButtonsBlocked} onClick={handleSubmit((data => onSubmit(data)))}>
              Save
            </Button.Main>
          </>
        ) : null}
        <Button.Main $primary $style="gray" onClick={() => navigate('/purchase-orders/' + orderId, {state: {tabToSwitch: "receiving"}})} type="button">
          Discard
        </Button.Main>
      </div>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />: null}
    </>
  );
};

export default ReceivedItems;
