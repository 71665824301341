import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import spmsServiceService from "../../services/spmsService.service";
import generalStyles from "../../styles/general.module.css";
import Label from "../../components/UI/Forms/Label";
import Select from "react-select";
import Input from "../../components/UI/Forms/Input";
import DatePicker from "react-datepicker";
import {paymentMethods} from "../../constants/paymentMethods";
import Docs from "../../components/Admins/PurchaseOrders/Modals/Docs";
import * as Button from "../../components/UI/Forms/Button";
import Toast from "../../components/UI/General/Toast";
import Box from "../../components/UI/General/Box";
import Title from "../../components/Admins/PurchaseOrders/View/Title";
import {useStore} from "../../store/store";
import Text from "../../components/UI/Typography/Text";
import moment from "moment";

const PaymentItem = () => {
  const { orderId, paymentItemId } = useParams();
  const userId = useStore((state) => state.user?.id);
  const navigate = useNavigate()
  const [tab, setTab] = useState('Notes');
  const [values, setValues] = useState(null)
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [invoiceOptions, setInvoiceOptions] = useState([])
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false)
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      linkedInvoice: [],
      paymentMethod: [],
      amount: '',
      reference: '',
      date: new Date(),
      effectivePayDate: '',
      batchReference: '',
      notes: '',
      files: [],
    },
    values
  });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data) => {
    console.log(data);
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const { files, amount, paymentMethod, linkedInvoice, ...other } = data;
    let newFiles = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newFiles = await Promise.all(filesArray).then((result) => result);
    }
    if (!newFiles?.length) {
      setIsButtonsBlocked(false)
      setTab('Documents');
      requiredRef.current = true
      setError('files', { type: 'focus', message: 'Files required' });
      return;
    }
    const newData = {
      ...other,
      amount: parseFloat(amount).toFixed(2),
      paymentMethod: paymentMethod.value,
      attachments: newFiles,
      invoice: {
        id: linkedInvoice.value
      },
    };
    console.log(newData);
    if (orderId && paymentItemId) {
      await spmsServiceService
        .updatePurchaseOrderPayment(orderId, paymentItemId, newData)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Payment updated successfully',
            type: 'success',
            cb: navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: "payments" } }),
          });
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false)
          setToast({
            opened: true,
            message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
            type: 'fail',
          });
        });
    }
  };

  useEffect(() => {
    spmsServiceService.getPurchaseOrderPayment(orderId, paymentItemId).then((r) => {
      if (r.data.message === 'Operation Successful') {
        const {linkedInvoice, effectivePayDate, paymentMethod, invoice : {id}, ...rest} = r.data.data
        console.log(effectivePayDate)
        console.log(new Date(effectivePayDate))
        console.log(moment(new Date().toISOString()).format('DD/MM/YYYY'))
        console.log(moment(effectivePayDate))
        console.log(moment(effectivePayDate).format('DD/MM/YYYY'))
        const data = {
          ...rest,
          linkedInvoice: invoiceOptions.find(el => el.value === id),
          paymentMethod: paymentMethods.find(el => el.value === paymentMethod),
          effectivePayDate: new Date(effectivePayDate),
        }
        setValues(data)
      }
    }).catch(err => {
      setToast({
        opened: true,
        message: err?.response?.data?.message ?? 'some error',
        type: 'fail',
      });
    });
  },[invoiceOptions, orderId, paymentItemId])

  useEffect(() => {
    spmsServiceService.getPurchaseOrderInvoices(orderId).then((r) => {
      if (r.data.message === 'Operation Successful') {
        const options = r.data.data.filter(item => item.approvalStatus === "APPROVED").map(el => ({label: el.invoiceNumber, value: el.id}))
        setInvoiceOptions(options);
      }
    }).catch(err => {
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    });
  },[orderId])

  const showToCreator = useMemo(()=> values?.paymentStatus === "PARTIALLY_PAID" && values?.createdBy?.userId === userId,[userId, values])
  console.log(values)
  return (
    <>
      <Title
        title="Payment"
        poState={values?.paymentStatus}
      />
      <Box $mobExtend $asHolder>
        <form>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Label $title="Linked Invoice" $isRequired={showToCreator}/>
              {!showToCreator ? <Text type={"body-1"}>{values?.linkedInvoice?.label}</Text> : (
                <>
                  <Controller
                    name="linkedInvoice"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Linked Invoice is required',
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                        classNamePrefix="react-select"
                        isSearchable={false}
                        isDisabled={!showToCreator}
                        placeholder="Select Invoice"
                        options={invoiceOptions}
                      />
                    )}
                  />
                  {errors.linkedInvoice && <p className="error-message">{errors.linkedInvoice.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {!showToCreator ? (
                <>
                  <Label $title="Payment Reference"/>
                  <Text type={"body-1"}>{values?.reference}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="reference"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Payment Reference is Required',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Maximum 50 characters',
                      },
                      validate: {
                        allowed: (v) =>
                          /^[-&()0-9A-Za-z\s]*$/.test(v) ||
                          'Alphabetic, numeric, &()- characters and space only',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Enter Payment Reference"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        $label="Payment Reference"
                        $labelRequired
                        disabled={!showToCreator}
                        {...field}
                      />
                    )}
                  />
                  {errors.reference && <p className="error-message">{errors.reference.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {!showToCreator ? (
                <>
                  <Label $title="Effective Pay Date"/>
                  <Text type={"body-1"}>{moment(values?.effectivePayDate).format('DD/MM/YYYY')}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="effectivePayDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Effective Pay Date is required',
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          {...field}
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          placeholderText="Enter Effective Pay Date"
                          selected={field.value}
                          disabled={!showToCreator}
                          wrapperClassName="custom-datepicker"
                          customInput={
                            <Input
                              $label="Effective Pay Date"
                              $labelRequired
                              className={errors.hasOwnProperty(field.name) && 'error'}
                            />
                          }
                        />
                      );
                    }}
                  />
                  {errors.effectivePayDate && <p className="error-message">{errors.effectivePayDate.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              <Label $title="Payment Method" $isRequired={showToCreator}/>
              {!showToCreator ? <Text type={"body-1"}>{values?.paymentMethod?.label}</Text> : (
                <>
                  <Controller
                    name="paymentMethod"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Payment Method is required',
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                        classNamePrefix="react-select"
                        isSearchable={false}
                        isDisabled={!showToCreator}
                        placeholder="Select Payment Method"
                        options={paymentMethods || []}
                      />
                    )}
                  />
                  {errors.paymentMethod && <p className="error-message">{errors.paymentMethod.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {!showToCreator ? (
                <>
                  <Label $title="Batch Reference"/>
                  <Text type={"body-1"}>{values?.batchReference}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="batchReference"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Batch Reference is required',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Enter Batch Reference"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        $label="Batch Reference"
                        $labelRequired
                        disabled={!showToCreator}
                        {...field}
                      />
                    )}
                  />
                  {errors.batchReference && <p className="error-message">{errors.batchReference.message}</p>}
                </>
              )}
            </div>
            <div className="inp-container">
              {!showToCreator ? (
                <>
                  <Label $title="Amount"/>
                  <Text type={"body-1"}>{values?.amount}</Text>
                </>
              ) : (
                <>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Amount is required',
                      },
                      maxLength: {
                        value: 10,
                        message: "Maximum 10 characters"
                      },
                      min: {
                        value: 0.01,
                        message: "Min 0.01"
                      },
                      validate: {
                        allowed: v => /^[0-9]+([.][0-9]{1,2})?$/.test(v) || 'Max depth 0.00',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Enter Amount"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        $label="Amount"
                        $labelRequired
                        disabled={!showToCreator}
                        {...field}
                      />
                    )}
                  />
                  {errors.amount && <p className="error-message">{errors.amount.message}</p>}
                </>
              )}
            </div>
          </div>
          <Docs
            errors={errors}
            control={control}
            nameNotes={'notes'}
            nameFiles={'files'}
            labelNotes={'Notes'}
            labelFiles={'Documents'}
            tab={tab}
            setTab={setTab}
            requiredRef={requiredRef}
            clearErrors={clearErrors}
            showInteraction={showToCreator}
            attachments={values?.attachments}
            notesList={values?.notesList ?? []}
          />
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        {showToCreator ? (
          <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid || isButtonsBlocked} onClick={handleSubmit((data => onSubmit(data)))}>
            Save
          </Button.Main>
        ) : null}
        <Button.Main $primary $style="gray" onClick={() => navigate('/purchase-orders/' + orderId, {state: {tabToSwitch: "payments"}})} type="button">
          Discard
        </Button.Main>
      </div>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} /> : null}
    </>
  );
};

export default PaymentItem;
