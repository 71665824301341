import { useStore } from './store';

export const userSlice = (set) => ({
  user: null,
  updateUser: (data) => set({ user: data }),
  reset: () => {
    set({ user: null });
    useStore.setState({
      company: null,
      companyId: null,
      stepsDone: false,
      activeCompany: null,
      fullCompanies: [],
    });
  },
});
