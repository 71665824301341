import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import CompanyList from '../pages/Companies/CompanyList';
import Taxes from '../pages/Tax/Taxes';
import TaxAdd from '../pages/Tax/TaxAdd';
import CompanyVendorSetup from '../pages/Vendor/CompanyVendorSetup';
import PendingDashboard from '../pages/Vendor/PendingDashboard';
import ActiveDashboard from '../pages/Vendor/ActiveDashboard';
import VendorSetup from '../pages/Vendor/VendorSetup';
import Sales from '../pages/Sales/Sales';
import Customers from '../pages/Customers/Customers';
import AddCustomer from '../pages/Customers/AddCustomer';
import BudgetsPendingApproval from '../pages/Budgets/BudgetsPendingApproval';
import Reports from '../pages/Reports/Reports';
import RequisitionPOReports from '../pages/Reports/RequisitionPOReports';
import VendorReports from '../pages/Reports/VendorReports';
import BudgetReports from '../pages/Reports/BudgetReports';
import DepartmentReports from '../pages/Reports/DepartmentReports';
import UserReports from '../pages/Reports/UserReports';
import AccountsBilling from '../pages/AccountsBilling/AccountsBilling';
import AccountOverview from '../pages/AccountsBilling/AccountOverview';
import AccountSubscription from '../pages/AccountsBilling/AccountSubscription';
import CompanyInfo from '../pages/AccountsBilling/AccountCompanyInfo';
import AccountPayments from '../pages/AccountsBilling/AccountPayments';
import AccountInvoices from '../pages/AccountsBilling/AccountInvoices';
import Setup from '../components/Layouts/Setup';
import CompanySetup from '../pages/Companies/CompanySetup';
import SubsidiarySetup from '../pages/Companies/SubsidiarySetup';
import AddNewSubsidiary from '../components/Admins/Company/AddNewSubsidiary';
import GlSetup from '../pages/Companies/GL/GLSetup';
import GlAdd from '../pages/Companies/GL/GLAdd';
import DepartmentSetup from '../pages/Companies/Departments/DepartmentSetup';
import DepartmentAdd from '../pages/Companies/Departments/DepartmentAdd';
import NotificationList from '../pages/Notification/NotificationList';
import AddNotification from '../pages/Notification/AddNotification';
import Create from '../pages/Users/Create';
import ApprovalWorkflow from '../pages/ApprovalWorkflow/ApprovalWorkflow';
import ApprovalAddRule from '../pages/ApprovalWorkflow/ApprovalAddRule';
import NtList from '../pages/NotificationTemplates/NtList';
import NtAdd from '../pages/NotificationTemplates/NtAdd';
import Profile from '../pages/Users/Profile';
import Requisitions from '../pages/Requisition/Requisitions';
import RequisitionCreate from '../pages/Requisition/RequisitionCreate';
import RequisitionView from '../pages/Requisition/RequisitionView';
import PurchaseOrders from '../pages/PurchaseOrder/PurchaseOrders';
import PurchaseOrdersCreate from '../pages/PurchaseOrder/Create';
import PurchaseOrder from '../pages/PurchaseOrder/Order';
import PurchaseOrderInvoice from '../pages/PurchaseOrder/Invoice';
import SystemRolesPermissions from '../pages/SystemRolesPermissions/SystemRolesPermissions';
import CreateRole from '../pages/Roles/CreateRole';
import LegalRegions from '../pages/LegalRegions/LegalRegions';
import Currencies from '../pages/Currencies/Currencies';
import Integrations from '../pages/Integrations/Integrations';
import Agents from '../pages/Agents/Agents';
import CreateAgent from '../pages/Agents/CreateAgent';
import Receiving from '../pages/Receiving/Receiving';
import Invoices from '../pages/Invoices/Invoices';
import Payments from '../pages/Payments/Payments';
import Budgets from '../pages/Budgets/Budgets';
import BudgetsCreate from '../pages/Budgets/BudgetsCreate';
import BudgetDetails from '../pages/Budgets/BudgetDetails';
import Users from '../pages/Users/Users';
import Roles from '../pages/Roles/Roles';
import { poLoader } from './loaders/po';
import { AdminErrorBoundary } from './errorElements/admin';
import { requisitionLoader } from './loaders/requisition';
import { budgetLoader } from './loaders/budget';
import SystemAudit from '../pages/Audit/SystemAudit';
import PoSetup from "../pages/Companies/POSetup";
import ReceivedItems from "../pages/PurchaseOrder/ReceivedItems";
import PaymentItem from "../pages/PurchaseOrder/PaymentItem";

export const adminRoutes =
    <Route errorElement={<AdminErrorBoundary/>}>
      <Route
        path="/dashboard"
        element={<Dashboard />}
      />
      <Route path="/company-list" element={<CompanyList />} />
      <Route
        path="/notification-templates/overview/:tab"
        element={<NtList />}
      />
      <Route
        path="/notification-templates/create"
        element={<NtAdd />}
      />
      <Route
        path="/notification-templates/edit/:templateId"
        element={<NtAdd />}
      />
      <Route path="/taxes/:tab" element={<Taxes />} />
      <Route path="/taxes/add" element={<TaxAdd />} />
      <Route path="/taxes/edit/:taxId" element={<TaxAdd />} />
      <Route
        path="/users/profile"
        element={<Profile />}
      />
      <Route
        path="/requisitions/overview/:tab"
        element={<Requisitions />}
      />
      <Route
        path="/requisitions/create"
        element={<RequisitionCreate />}
      />
      <Route
        path="/requisitions/edit/:requisitionId"
        loader={requisitionLoader}
        element={<RequisitionCreate />}
      />
      <Route
        path="/requisitions/:requisitionId"
        loader={requisitionLoader}
        element={<RequisitionView />}
      />
      <Route
        path="/purchase-orders/overview/:tab"
        element={<PurchaseOrders />}
      />
      <Route
        path="/purchase-orders/create"
        element={<PurchaseOrdersCreate />}
      />
      <Route
        path="/purchase-orders/edit/:orderId"
        loader={poLoader}
        element={<PurchaseOrdersCreate />}
      />
      <Route
        path="/purchase-orders/:orderId"
        loader={poLoader}
        element={<PurchaseOrder />}
      />
      <Route
        path="/purchase-orders/:orderId/invoice"
        loader={poLoader}
        element={<PurchaseOrderInvoice />}
      />
      <Route
        path="/purchase-orders/:orderId/invoices/:invoiceId"
        loader={poLoader}
        element={<PurchaseOrderInvoice />}
      />
      <Route
        path="/purchase-orders/:orderId/received-items/:receivedItemId"
        loader={poLoader}
        element={<ReceivedItems />}
      />
      <Route
        path="/purchase-orders/:orderId/payments/:paymentItemId"
        loader={poLoader}
        element={<PaymentItem />}
      />
      <Route path="/vendors/:tab" element={<CompanyVendorSetup />} />
      <Route
        path="/vendors/:companyId/viewPending/:vendorId"
        element={<PendingDashboard />}
      />
      <Route
        path="/vendors/:companyId/viewActive/:vendorId"
        element={<ActiveDashboard />}
      />
      <Route path="/vendors/:companyId/:mode" element={<VendorSetup />} />
      <Route path="/vendors/:companyId/:mode/:vendorId" element={<VendorSetup />} />
      <Route
        path="/system-roles-and-permissions"
        element={<SystemRolesPermissions />}
      />
      <Route
        path="/system-roles-and-permissions/edit/:roleId"
        element={<CreateRole />}
      />

      {/*non filled and functioning pages start*/}
      <Route
        path="/legal-regions"
        element={<LegalRegions />}
      />
      <Route
        path="/currencies"
        element={<Currencies />}
      />
      <Route
        path="/integrations"
        element={<Integrations />}
      />
      <Route
        path="/agents/overview"
        element={<Agents />}
      />
      <Route
        path="/agents/create"
        element={<CreateAgent />}
      />
      {/*non filled and functioning pages end*/}
      <Route
        path="/receiving/overview/:tab"
        element={<Receiving />}
      />
      <Route
        path="/invoices/overview/:tab"
        element={<Invoices />}
      />
      <Route
        path="/payments/overview/:tab"
        element={<Payments />}
      />
      <Route path="/sales" element={<Sales />} />
      <Route path="/customers/overview" element={<Customers />} />
      <Route path="/customers/onboard-new-customer" element={<AddCustomer />} />
      <Route
        path="/budgets/:tab"
        element={<Budgets />}
      />
      <Route
        path="/budgets/:companyId/create"
        element={<BudgetsCreate />}
      />
      <Route
        path="/budgets/:companyId/edit/:budgetId"
        loader={budgetLoader}
        element={<BudgetsCreate />}
      />
      <Route
        path="/budgets/:companyId/details/:budgetId"
        loader={budgetLoader}
        element={<BudgetDetails />}
      />
      <Route
        path="/budgets/:companyId/pending-approval/:budgetId"
        loader={budgetLoader}
        element={<BudgetsPendingApproval />}
      />
      <Route path="/reports/overview" element={<Reports />} />
      <Route path="/reports/requisitions" element={<RequisitionPOReports />} />
      <Route path="/reports/purchase-orders" element={<RequisitionPOReports />} />
      <Route path="/reports/vendors" element={<VendorReports />} />
      <Route path="/reports/budgets" element={<BudgetReports />} />
      <Route path="/reports/departments" element={<DepartmentReports />} />
      <Route path="/reports/user" element={<UserReports />} />
      <Route path="/system-audit" element={<SystemAudit />} />
      <Route element={<AccountsBilling />}>
        <Route path="/accounts-billing/overview" element={<AccountOverview />} />
        <Route path="/accounts-billing/subscription" element={<AccountSubscription />}/>
        <Route path="/accounts-billing/company-information" element={<CompanyInfo />}/>
        <Route path="/accounts-billing/payment" element={<AccountPayments />} />
        <Route path="/accounts-billing/invoices" element={<AccountInvoices />} />
      </Route>
      <Route element={<Setup />}>
        <Route path="/company-setup" element={<CompanySetup />} />
        <Route path="/subsidiary-setup" element={<SubsidiarySetup />} />
        <Route path="/subsidiary-setup/add" element={<AddNewSubsidiary />} />
        <Route path="/subsidiary-setup/edit/:id" element={<AddNewSubsidiary />} />
        <Route path="/gl-setup" element={<GlSetup />} />
        <Route path="/gl-setup/:companyId/add" element={<GlAdd />} />
        <Route path="/gl-setup/:companyId/edit/:glId" element={<GlAdd />} />
        <Route path="/department-setup" element={<DepartmentSetup />} />
        <Route path="/department-setup/:companyId/edit/:departmentId" element={<DepartmentAdd />}/>
        <Route path="/notification" element={<NotificationList />} />
        <Route path="/notification/:companyId/add" element={<AddNotification />} />
        <Route path="/notification/:companyId/edit/:id" element={<AddNotification />}/>
        <Route path="/department-setup/:companyId/add" element={<DepartmentAdd />}/>
        <Route path="/users/overview/:tab" element={<Users />}/>
        <Route path="/users/add" element={<Create />}/>
        <Route path="/users/edit/:userId" element={<Create />}/>
        <Route path="/approval" element={<ApprovalWorkflow />} />
        <Route path="/approval/:companyId/add-rule" element={<ApprovalAddRule />} />
        <Route path="/approval/:companyId/edit-rule/:ruleId" element={<ApprovalAddRule />}/>
        <Route path="/user-roles-and-permissions/:tab" element={<Roles />}/>
        <Route path="/user-roles-and-permissions/create" element={<CreateRole />}/>
        <Route path="/user-roles-and-permissions/edit/:roleId" element={<CreateRole />}/>
        <Route path="/po-setup" element={<PoSetup/>}/>
      </Route>
    </Route>
