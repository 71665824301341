import React, {useEffect, useMemo, useState} from 'react';
import { Link } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import Icon from '../../components/UI/General/Icon';
import styles from '../../styles/dashboard.module.css';
import Select from 'react-select';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import { useStore } from '../../store/store';
import { useShallow } from 'zustand/react/shallow';
import s2pmsService from '../../services/spmsService.service';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import budgetService from '../../services/budgetService.service';
import { ProgressBarFill, ProgressBarWrapper } from '../../components/UI/General/ProgressBar';
import Widget from '../../components/Admins/Dashboard/Widget';
import {useSubscriptionAllowed} from "../../hooks/useSubscriptionAllowed";
import DataTableBase from "../../components/UI/General/DataTableBase";
import generalStyles from "../../styles/general.module.css";
import moment from "moment";
import {NumericFormat} from "react-number-format";
import Loading from "../../components/Loading";
import spmsServiceService from "../../services/spmsService.service";

const months = [
  { label: 'Current Month', value: 1 },
  { label: 'Last Month', value: 2 },
  { label: 'Last Quarter', value: 3 },
  { label: 'Year to date', value: 4 },
];

const dataJune = [
  {
    name: 'Week 01',
    spend: 4000,
    budget: 2400,
  },
  {
    name: 'Week 02',
    spend: 3000,
    budget: 1398,
  },
  {
    name: 'Week 03',
    spend: 2000,
    budget: 9800,
  },
  {
    name: 'Week 04',
    spend: 2780,
    budget: 3908,
  },
  {
    name: 'Month end',
    spend: 2780,
    budget: 3908,
  },
];
const dataJuly = [
  {
    name: 'Week 01',
    spend: 5000,
    budget: 3400,
  },
  {
    name: 'Week 02',
    spend: 43000,
    budget: 2398,
  },
  {
    name: 'Week 03',
    spend: 1000,
    budget: 8800,
  },
  {
    name: 'Week 04',
    spend: 1780,
    budget: 5908,
  },
  {
    name: 'Month end',
    spend: 2280,
    budget: 3108,
  },
];
const dataAugust = [
  {
    name: 'Week 01',
    spend: 3000,
    budget: 2200,
    amt: 2400,
  },
  {
    name: 'Week 02',
    spend: 2300,
    budget: 1198,
  },
  {
    name: 'Week 03',
    spend: 2000,
    budget: 9100,
  },
  {
    name: 'Week 04',
    spend: 2780,
    budget: 3108,
  },
  {
    name: 'Month end',
    spend: 2780,
    budget: 3608,
  },
];

function Dashboard() {
  const user = useStore(useShallow((state) => state.user));
  const activeCompany = useStore((state) => state.activeCompany);
  const hasFullCompanies = useStore((state) => state.fullCompanies);
  const stepsDone = useStore(useShallow((state) => state.stepsDone));
  const [loadedCardData, setLoadedCardData] = useState(false)
  const [cardData, setCardData] = useState({
    requisition: {
      drafts: 0,
      pending: 0,
    },
    po: {
      approved: 0,
      pending: 0,
      pendingReceipt: 0,
      pendingInvoices: 0,
      readyToPay: 0,
      completed: 0
    },
  });
  const [tableData, setTableData] = useState([]);
  const [monthA, setMonthA] = useState(months[0]);
  const [monthB, setMonthB] = useState(months[0]);
  const [dataA, setDataA] = useState(dataJune);
  const [dataB, setDataB] = useState(dataJune);
  const [budgetChart, setBudgetChart] = useState(undefined);
  const [budgetCharts, setBudgetCharts] = useState([]);

  const accessBudgets = useAccessAllowed('Budgets');
  const accessSubscription = useSubscriptionAllowed()

  useEffect(() => {
    switch (monthA.value) {
      case 1:
        setDataA(dataJune);
        break;
      case 2:
        setDataA(dataJuly);
        break;
      case 3:
        setDataA(dataAugust);
        break;
      case 4:
        setDataA(dataJune);
        break;
      default:
        setDataA(dataJune);
    }
  }, [monthA]);

  useEffect(() => {
    switch (monthB.value) {
      case 1:
        setDataB(dataJune);
        break;
      case 2:
        setDataB(dataJuly);
        break;
      case 3:
        setDataB(dataAugust);
        break;
      case 4:
        setDataB(dataJune);
        break;
      default:
        setDataA(dataJune);
    }
  }, [monthB]);

  useEffect(() => {
    console.log(budgetChart?.value)
  }, [budgetChart]);

  const columns = useMemo(
    () => [
      {
        name: 'Budget Name',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'Budget Name'} className={generalStyles.tableValue}>
            {accessBudgets?.viewOwn ? (
              <Link to={`/budgets/${activeCompany?.id}/details/${row?.id}`} className={styles.tableLink} state={{ row }}>
                <span style={{ textDecoration: 'underline', color: '#c85d9f' }}>{row.name}</span>
              </Link>
            ) : (
              <span>{row.name}</span>
            )}
          </span>
        )
      },
      {
        name: 'Start Date',
        sortable: true,
        selector: (row) => row.startDate,
        cell: (row) => (
          <span data-content={'Start Date'} className={generalStyles.tableValue}>
            <span>{moment(row?.startDate).format('DD/MM/YYYY')}</span>
          </span>
        ),
        wrap: true,
      },
      {
        name: 'End Date',
        sortable: true,
        selector: (row) => row.endDate,
        cell: (row) => (
          <span data-content={'End Date'} className={generalStyles.tableValue}>
            <span>{moment(row?.endDate).format('DD/MM/YYYY')}</span>
          </span>
        ),
      },
      {
        name: 'Budget Type',
        sortable: true,
        selector: (row) => row.budgetType,
        cell: (row) => {
          const text = row?.budgetType?.split('_')[0]?.toLowerCase();
          return (
            <span data-content={'End Date'} className={generalStyles.tableValue}>
              <span>{text?.charAt(0)?.toUpperCase() + text?.slice(1)}</span>
            </span>
          );
        },
      },
      {
        name: 'Total Allocated',
        sortable: true,
        selector: (row) => row.budgetValue,
        cell: (row) => (
          <span data-content={'Total Allocated'} className={generalStyles.tableValue}>
            <NumericFormat
              value={row?.budgetValue}
              displayType="text"
              thousandSeparator=","
              prefix={`${row?.currency} `}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        name: 'Used',
        selector: (row) => ((row?.totalPOApprovedAmount / row.budgetValue) * 100).toFixed(0),
        cell: (row) =>(
          <span data-content={'Used'} className={generalStyles.tableValue}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                rowGap: '2px',
              }}
            >
              <span>{((row?.totalPOApprovedAmount / row.budgetValue) * 100).toFixed(0)} %</span>
              <ProgressBarWrapper width={100}>
                <ProgressBarFill
                  progress={((row.totalPOApprovedAmount / row.budgetValue) * 100).toFixed(0)}
                />
              </ProgressBarWrapper>
            </div>
          </span>
        ),
        sortable: true,
      },
    ],
    [accessBudgets, activeCompany],
  );

  useEffect(() => {
    if (!!activeCompany?.id) {
      spmsServiceService.getMetrics(activeCompany?.id).then(r => {
        if (r.data.message === "Operation Successful"){
          console.log(r.data.data)
          const data = {
            requisition: {
              drafts: parseInt(r.data.data.requisitions),
              pending: parseInt(r.data.data.requisitionsPendingApproval),
            },
            po: {
              approved: 0,
              pending: parseInt(r.data.data.purchaseOrdersPendingApproval),
              pendingReceipt: 0,
              pendingInvoices: 0,
              readyToPay: 0,
              completed: parseInt(r.data.data.purchaseOrdersCompleted)
            },
          }
          setCardData(data);
          setLoadedCardData(true);
        }
      })
    }
  }, [activeCompany]);

  const renderLineChart = (
    <ResponsiveContainer minWidth={300} aspect={4}>
      <LineChart
        width={600}
        height={300}
        data={dataA}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <Line type="monotone" dataKey="spend" stroke="#C85D9F" activeDot={{ r: 8 }} />
        <XAxis dataKey="name" stroke="#ACAEC9" />
        <YAxis stroke="#ACAEC9" />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
  const renderAreaChart = (
    <ResponsiveContainer minWidth={300} aspect={4}>
      <AreaChart data={dataB} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorSpend" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#C85D9F" stopOpacity={0.8} />
            <stop offset="80%" stopColor="#C85D9F" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorBudget" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#383E90" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#383E90" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" stroke="#ACAEC9" />
        <YAxis stroke="#ACAEC9" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="spend"
          stroke="#C85D9F"
          fillOpacity={1}
          fill="url(#colorSpend)"
          activeDot={{ r: 8 }}
        />
        <Area
          type="monotone"
          dataKey="budget"
          stroke="#383E90"
          fillOpacity={1}
          fill="url(#colorBudget)"
          activeDot={{ r: 8 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  useEffect(() => {
    const fetchBudgets = async () => {
      await budgetService.getAllBudgetData(activeCompany.id).then((r) => {
        if (r.data.message === 'Operation Successful') {
          if (r?.data?.data?.length > 0) {
            const data = r.data.data.filter((item) => item?.active && item?.budgetStatus === "ACTIVE")
            const bNames = data.map(item => ({label: item.name, value: item.id}))
            setTableData(data);
            setBudgetCharts(bNames)
            setBudgetChart(bNames[0])
          }else {
            setTableData(undefined);
          }
        }
      });
    };
    if (!!activeCompany?.id) {
      fetchBudgets();
    }
  }, [activeCompany]);

  if((!loadedCardData || (tableData !== undefined && !tableData?.length) || !accessBudgets || !accessSubscription) && stepsDone){
    return <Loading text={"Loading company data..."}/>
  }
  console.log(hasFullCompanies)
  console.log(!!hasFullCompanies?.length)
  console.log(user.roles.filter(el => el.company?.id === activeCompany?.id && el.role?.name === "END_USER")?.length > 1)
  return (
    <>
      <Text type="h2" weight={600}>Dashboard</Text>

      {!!hasFullCompanies?.length || user.roles.filter(el => el.company?.id === activeCompany?.id && el.role?.name === "END_USER")?.length > 1 ? null : <Widget />}

      {stepsDone && (
        <>
          <div className={styles.counters}>
            <Link to={!activeCompany?.requisitionsRequired ? '' : '/requisitions/overview/drafts'}>
              <Box
                $noPadding
                $radius={12}
                className={!activeCompany?.requisitionsRequired ? styles.counter + ' ' + styles.counterDisabled : styles.counter}
              >
                <Text type="h1" className={styles.counterValue}>
                  {cardData.requisition.drafts}
                </Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>
                  My Requisitions in Drafts
                </Text>
                <div className={styles.counterIcon}>
                  <Icon $width={36} $height={36} $icon="dashboard-counter-1" />
                </div>
              </Box>
            </Link>
            <Link to={!activeCompany?.requisitionsRequired ? '' : '/requisitions/overview/pending'}>
              <Box
                $noPadding
                $radius={12}
                className={!activeCompany?.requisitionsRequired ? styles.counter + ' ' + styles.counterDisabled : styles.counter}
              >
                <Text type="h1" className={styles.counterValue}>
                  {cardData.requisition.pending}
                </Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>
                  Requisitions - Pending My Approval
                </Text>
                <div className={styles.counterIcon}>
                  <Icon $width={36} $height={36} $icon="dashboard-counter-2" />
                </div>
              </Box>
            </Link>
            <Link to="/purchase-orders/overview/approved">
              <Box $radius={12} className={styles.counter} $noPadding>
                <Text type="h1" className={styles.counterValue}>
                  {cardData.po.approved}
                </Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>
                  My PO’s - Approved
                </Text>
                <div className={styles.counterIcon}>
                  <Icon $width={36} $height={36} $icon="dashboard-counter-3" />
                </div>
              </Box>
            </Link>
            <Link to="/purchase-orders/overview/pending">
              <Box $radius={12} className={styles.counter} $noPadding>
                <Text type="h1" className={styles.counterValue}>
                  {cardData.po.pending}
                </Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>
                  PO’s - Pending My Approval
                </Text>
                <div className={styles.counterIcon}>
                  <Icon $width={36} $height={36} $icon="dashboard-counter-2" />
                </div>
              </Box>
            </Link>
            <Link to="/receiving/overview/not_fulfilled">
              <Box $radius={12} className={styles.counter} $noPadding>
                <Text type="h1" className={styles.counterValue}>{cardData.po.pendingReceipt}</Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>My PO’s - Pending Receipt</Text>
                <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-4" /></div>
              </Box>
            </Link>
            <Link to="/invoices/overview/outstanding">
              <Box $radius={12} className={styles.counter} $noPadding>
                <Text type="h1" className={styles.counterValue}>{cardData.po.pendingInvoices}</Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>My PO’s - Pending Invoices</Text>
                <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-5" /></div>
              </Box>
            </Link>
            <Link to="/payments/overview/outstanding">
              <Box $radius={12} className={styles.counter} $noPadding>
                <Text type="h1" className={styles.counterValue}>{cardData.po.readyToPay}</Text>
                <Text type="body-1" weight={500} className={styles.counterLabel}>My PO’s - Ready to Pay</Text>
                <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-6" /></div>
              </Box>
            </Link>
            <Box $radius={12} className={styles.counter} $noPadding>
              <Text type="h1" className={styles.counterValue}>{cardData.po.completed}</Text>
              <Text type="body-1" weight={500} className={styles.counterLabel}>My PO’s - Completed</Text>
              <div className={styles.counterIcon}>
                <Icon $width={36} $height={36} $icon="dashboard-counter-7" />
              </div>
            </Box>
          </div>

          {accessSubscription?.plan?.name === 'Basic' && (
            <Box $mobExtend $asHolder>
              <div className={styles.chartTop}>
                <Text type="h4" weight={600}>
                  Spend Summary
                </Text>
                <div className={styles.chartActions}>
                  <Select
                    name="monthsA"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isSearchable={false}
                    value={monthA}
                    onChange={(e) => setMonthA(e)}
                    options={months}
                  />
                </div>
              </div>
              {dataA && renderLineChart}
            </Box>
          )}

          {((accessBudgets?.viewOwn || accessBudgets?.viewAll) && accessSubscription?.plan?.budgetManagement) && (
            <Box $mobExtend $asHolder>
              <div className={styles.chartTop}>
                <Text type="h4" weight={600}>
                  Spend vs Budget Summary
                </Text>
                <div className={styles.chartActions}>
                  <Select
                    name="budgetChart"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isSearchable={false}
                    value={budgetChart}
                    onChange={(e) => setBudgetChart(e)}
                    options={budgetCharts}
                  /><Select
                  name="monthsB"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Region / State"
                  value={monthB}
                  onChange={(e) => setMonthB(e)}
                  options={months}
                />
                </div>
              </div>
              {dataB && renderAreaChart}
            </Box>
          )}

          {((accessBudgets?.viewOwn || accessBudgets?.viewAll) && accessSubscription?.plan?.budgetManagement) && (
            <Box $mobExtend $asHolder>
              <Text type='h3' weight={600}>Budgets</Text><br/>
              <DataTableBase
                data={tableData}
                columns={columns}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default Dashboard;
