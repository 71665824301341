import { Link, useNavigate } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import styles from '../../styles/reports.module.css';
import generalStyles from '../../styles/general.module.css';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';
import Box from '../../components/UI/General/Box.jsx';
import Icon from '../../components/UI/General/Icon.jsx';
import Status from '../../components/UI/Typography/Status';
import Filters from '../../components/Admins/Reports/Filters.jsx';
import useGetReportsData from '../../components/Admins/Reports/helpers/useGetReportsData.jsx';
import { useStore } from '../../store/store.js';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote.jsx';

const VendorReports = () => {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const activeCompany = useStore((state) => state.activeCompany);
  const navigate = useNavigate();
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const { getReports, getExport } = useGetReportsData(getValues(), fetchTrigger);

  const handleLink = (status, id, companyName) => {
    const state = {};
    const link =
      status === 'APPROVED'
        ? `/vendors/${activeCompany.id}/viewActive/${id}`
        : status === 'DRAFT'
        ? `/vendors/${activeCompany.id}/edit/${id}`
        : status === 'PENDING'
        ? `/vendors/${activeCompany.id}/viewPending/${id}`
        : status === 'ARCHIVED'
        ? `/vendors/overview`
        : `/vendors/${id}`;
    if (status === 'ARCHIVED') {
      state.id = id;
      state.companyId = activeCompany.id;
    }
    return { link, state };
  };

  const columns = useMemo(
    () => [
      {
        name: 'Vendor Name',
        selector: (row) => row.legalName,
        sortable: true,
        cell: (row) => (
          <Link
            state={handleLink(row.status, row.vendorId, row.companyName).state}
            className={styles.nameLink}
            to={handleLink(row.status, row.vendorId, row.companyName).link}
          >
            {row.legalName}
          </Link>
        ),
      },
      {
        name: 'Vendor Code',
        selector: (row) => row.vendorCode,
        wrap: true,
      },
      {
        name: 'Approver',
        selector: (row) => row.approver ?? '-',
      },
      {
        name: 'Status',
        selector: (row) => row.department,
        cell: (row) => <Status value={row.status} pill />,
      },
    ],
    [],
  );

  return (
    <div>
      <Text className={styles.sectionTitle} type="h2" weight={600}>
        Vendor Reports
      </Text>
      <Box $radius={12} className={styles.container}>
        <form className={styles.filterForm}>
          <Filters
            control={control}
            errors={errors}
            trigger={trigger}
            watch={watch}
            clearErrors={clearErrors}
            setValue={setValue}
            filters={['type', 'department', 'gl', 'users']}
            page="vendors"
          />
          <div className={generalStyles.pageButtons}>
            <Button.Main
              onClick={() => setFetchTrigger((fetchTrigger) => !fetchTrigger)}
              $primary
              $style="pink"
              type="button"
            >
              Submit
            </Button.Main>
            <Button.Main
              onClick={() => navigate('/reports/overview')}
              $primary
              $style="gray"
              type="button"
            >
              Back to Overview
            </Button.Main>
          </div>
        </form>
      </Box>
      <br />
      <br />
      <Box $radius={12} className={styles.container}>
        <div>
          <div className={styles.exportBtnContainer}>
            <Button.Main
              $primary
              $style="pink"
              onClick={() => getExport('Vendor Reports')}
              $iconLeft
            >
              <Icon $width={20} $height={20} $icon="export" />
              <Text weight={500} type="subtitle">
                Export
              </Text>
            </Button.Main>
          </div>
          <div className={styles.reportsTable}>
            <DataTableBaseRemote
              columns={columns}
              separatePagination
              fetchData={getReports}
              selectableRows={false}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default VendorReports;
