import React, {Suspense, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from "react-hook-form";
import DatePicker from 'react-datepicker'
import Text from "../../components/UI/Typography/Text";
import Input from "../../components/UI/Forms/Input";
import Select from "react-select";
import Label from "../../components/UI/Forms/Label";
import * as Button from "../../components/UI/Forms/Button";
import Box from "../../components/UI/General/Box";
import spmsServiceService from "../../services/spmsService.service";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import generalStyles from '../../styles/general.module.css'
import {useStore} from "../../store/store";
import CountryAndRegion from "../../components/UI/Forms/CountryAndRegion";
import Toast from "../../components/UI/General/Toast";

const types = [
  {label: "Value Added Tax (VAT)", value: "vat"},
  {label: "Excise Duty", value: "exciseDuty"}
]

const TaxAdd = () => {
  const {taxId} = useParams();
  const {id} = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null)
  const {handleSubmit, control, formState: {errors, isDirty, isValid}} = useForm({
    mode: 'onChange',
    defaultValues: {
      countryName: '',
      state: '',
      type: '',
      percentage: '',
      startDate: new Date(),
      linkTo: null,
    },
    values
  });
  const [linkTo, setLinkTo] = useState([])
  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    spmsServiceService.getTaxList(id).then(res => {
      if (res.data.status === "success") {
        const filtered = res.data.data.filter(item => item.active === true);
        setLinkTo(filtered);
      } else {
        alert("error" + res.data.message)
      }
    }).catch(error => (
      alert("error" + error.message)
    ));
  }, [id])

  useEffect(() => {
    if (taxId && id) {
      spmsServiceService.getTax(id, taxId).then((res) => {
        console.log(res.data.data)
        const {type, countryName, state, startDate, parent, ...others} = res.data.data;
        const typeUpd = types?.find(item => item.value === type)
        const startDateUpd = startDate.replace(/(\d+[/])(\d+[/])/, '$2$1');
        setValues({
          ...others,
          type: typeUpd,
          countryName: {label: countryName, value: countryName},
          state: {label: state, value: state},
          startDate: new Date(startDateUpd),
          linkTo: parent
        });
      });
    }
  }, [taxId, id])

  const onSubmit = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    let {countryName, state, type, startDate, percentage, linkTo} = data;
    const parentIdUpd = linkTo === null ? null : linkTo.id;
    const startDateUpd = moment(startDate).format('DD/MM/YYYY');
    if (taxId) {
      const updData = {
        countryName: countryName.value,
        state: state.value,
        type: type.value,
        percentage: parseInt(percentage),
        startDate: startDateUpd,
        parentId: parentIdUpd,
        taxId: taxId
      }
      console.log(updData)
      spmsServiceService.updateTax(id, updData).then(res => {
        console.log(res)
        setToast({
          opened: true,
          message: 'Tax updated successfully',
          type: 'success',
          cb: () => navigate('/taxes/active'),
        });
      }).catch((reason) => {
        console.log(reason);
        setToast({
          opened: true,
          message: reason.response.data.errors[0].message === undefined ? reason.response.data.message : reason.response.data.errors[0].message,
          type: 'fail',
        });
      });
    } else {
      const newData = {
        countryName: countryName.value,
        state: state.value,
        type: type.value,
        percentage: parseInt(percentage),
        startDate: startDateUpd,
        parentId: parentIdUpd
      }
      console.log(newData)
      spmsServiceService.createTax(id, newData).then(res => {
        console.log(res)
        setToast({
          opened: true,
          message: 'Tax created successfully',
          type: 'success',
          cb: () => navigate('/taxes/active'),
        });
      }).catch((reason) => {
        console.log(reason);
        setToast({
          opened: true,
          message: reason.response.data.errors[0].message === undefined ? reason.response.data.message : reason.response.data.errors[0].message,
          type: 'fail',
        });
      });
    }
  };

  return (
    <>
      <Text weight={600} type="h2">{taxId !== undefined ? "Edit" : "Add"} Tax Rate Card</Text>
      <Suspense fallback={<h2>Loading...</h2>}>
        <form id="taxForm">
          <Box $mobExtend $asHolder $noOverflow>
            <div className={generalStyles.fieldsThree}>
              <CountryAndRegion
                countryFieldName="countryName"
                regionFieldName="state"
                control={control}
                errors={errors}
              />
              <div className="inp-container">
                <Label $title="Type"/>
                <Controller
                  name="type"
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <Select
                      {...field}
                      className={errors.hasOwnProperty(field.name) ? "react-select-container error" : "react-select-container"}
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Type"
                      options={types}
                    />
                  )}
                />
                {errors.type?.type === 'required' && <p className="error-message">Type is required</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="percentage"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[0-9]+$/
                  }}
                  defaultValue=""
                  render={({field}) => (
                    <Input
                      type="text"
                      placeholder="10"
                      className={errors.hasOwnProperty(field.name) && "error"}
                      $label="% Applicable"
                      {...field}/>
                  )}
                />
                {errors.percentage?.type === 'required' && <p className="error-message">% Applicable is required</p>}
                {errors.percentage?.type === 'pattern' && <p className="error-message">Only numbers allowed</p>}
              </div>
              <div className="inp-container">
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    required: true
                  }}
                  render={({field}) => {
                    return (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Select date"
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        customInput={<Input
                          $label="Start Date"
                          className={errors.hasOwnProperty(field.name) && "error"}
                        />}
                      />
                    );
                  }}
                />
                {errors.startDate?.type === 'required' && <p className="error-message">Start Date is required</p>}
              </div>
              <div className="inp-container">
                <Label $title="Link to"/>
                <Controller
                  name="linkTo"
                  control={control}
                  render={({field}) => (
                    <Select
                      {...field}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isSearchable={false}
                      options={linkTo}
                      hideSelectedOptions
                      getOptionLabel={(option) => option.countryName + '-' + option.state + '-' + option.type}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
              </div>
            </div>
          </Box>
        </form>
        <div className={generalStyles.pageButtons}>
          <Button.Main
            $primary
            $style="pink"
            type="submit"
            form="taxForm"
            onClick={handleSubmit((data) => onSubmit(data))}
            disabled={!isValid || !isDirty}
          >
            Save
          </Button.Main>
          <Button.Main
            $primary
            $style="gray"
            type="button"
            onClick={() => navigate('/taxes/active')}
          >
            Discard
          </Button.Main>
        </div>
      </Suspense>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} /> : null}
    </>
  );
};

export default TaxAdd;
