import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import DataTableBase from '../../../UI/General/DataTableBase';
import { Link, useParams } from 'react-router-dom';
import Text from '../../../UI/Typography/Text';
import spmsServiceService from '../../../../services/spmsService.service';
import moment from 'moment';
import { downloadExternal } from '../../../../utils/downloadExternal';
import generalStyles from "../../../../styles/general.module.css";
import Status from "../../../UI/Typography/Status";
import {nf} from "../../../../utils/nf";
import * as Button from "../../../UI/Forms/Button";
import Icon from "../../../UI/General/Icon";
import {useStore} from "../../../../store/store";

const InvoicesTab = (props) => {
  const { orderId } = useParams();
  const user = useStore((state) => state.user);
  const [tableData, setTableData] = useState([]);
  const [updateData, setUpdateDate] = useState(false)

  const getDocument = (key, name) => {
    spmsServiceService.getAttachmentsByKey(key).then((res) => {
      const url = res.data.data;
      const fileType = name.split('.').at(-1);
      downloadExternal(url, fileType);
    });
  };

  const deleteItem = (itemId) => {
    props.setToast((item) => ({ ...item, opened: false }));
    spmsServiceService.deletePurchaseOrderInvoice(orderId, itemId).then(() => {
      props.setToast({
        opened: true,
        message: 'Invoice has been deleted successfully',
        type: 'success',
        cb: () => (setUpdateDate(s => !s),props.$trigger(state => state +1)),
      });
    }).catch(err => {
      props.setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    })
  }

  const columns = useMemo(
    () => [
      {
        name: 'Received Date',
        sortable: true,
        selector: (row) => row?.invoiceReceivedDate,
        format: (row) => moment(row?.invoiceReceivedDate).format('DD/MM/YYYY'),
        cell: row => <span data-content={"Date Created"} className={generalStyles.tableValue} data-tag="allowRowEvents">
          <Link to={'/purchase-orders/' + orderId + '/invoices/' + row.id} className={styles.linkHighlighted}>
              {moment(row?.invoiceReceivedDate).format('DD/MM/YYYY')}
            </Link>
        </span>,
      },
      {
        name: 'Invoice Number',
        sortable: true,
        selector: (row) => row?.invoiceNumber,
        cell: (row) => (
          <span data-content={"Action by"} className={generalStyles.tableValue}>
            <span data-tag="allowRowEvents">{row?.invoiceNumber}</span>
          </span>
        ),
      },
      {
        name: 'Action by',
        sortable: true,
        selector: (row) => row?.updatedBy?.name,
        cell: row => <span data-content={"Action by"} className={generalStyles.tableValue} data-tag="allowRowEvents"><span data-tag="allowRowEvents">{row?.updatedBy.name ?? "-"}</span></span>,
      },
      {
        name: 'Amount',
        sortable: true,
        selector: (row) => row?.totalInvoiceAmount?.toFixed(2),
        cell: row => <span data-content={"Amount"} className={generalStyles.tableValue} data-tag="allowRowEvents"><span data-tag="allowRowEvents">{nf.format(row?.totalInvoiceAmount) ?? "-"}</span></span>,
      },
      {
        name: 'Status',
        sortable: true,
        selector: row => row?.approval?.approvalStatus,
        cell: (row) => (
          <>
            <span data-content={"Status"} className={generalStyles.tableValue} data-tag="allowRowEvents">
              <Text
                weight={500}
                type={'body-2'}
                data-tag="allowRowEvents"
              >
                <Status value={row?.approvalStatus} wordCount={1} pill/>
              </Text>
            </span>
            {row?.createdBy?.userId === user.id && row?.approvalStatus === "PENDING_APPROVAL" ? (
              <div className={generalStyles.actionMenuHolder}>
                <Button.Action $variant="circle" $style="lightGrayishCyan" $width={32} $height={32} onClick={() => deleteItem(row?.id)} type="button">
                  <Icon $width={20} $height={20} $icon="delete" $color="#F24638"/>
                </Button.Action>
              </div>
            ) : null}
          </>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    spmsServiceService.getPurchaseOrderInvoices(orderId).then((r) => {
      if (r.data.message === 'Operation Successful') {
        setTableData(r.data.data);
      }
    });
  }, [orderId, updateData]);

  return (
    <DataTableBase
      data={tableData}
      columns={columns}
      pagination={false}
    />
  );
};

export default InvoicesTab;
