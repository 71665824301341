import React from 'react';

const Integrations = () => {
  return (
    <div>
      Integrations
    </div>
  );
};

export default Integrations;
