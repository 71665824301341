import React, { useEffect, useRef, useState } from 'react';
import styles from '../../../styles/companySetup.module.css';
import { Controller, useForm } from 'react-hook-form';
import spmsServiceService from '../../../services/spmsService.service';
import generalStyles from '../../../styles/general.module.css';
import Input from '../../../components/UI/Forms/Input';
import Label from '../../../components/UI/Forms/Label';
import Select from 'react-select';
import Text from '../../../components/UI/Typography/Text';
import MultiSelectOpened from '../../../components/UI/Forms/MultiSelectOpened';
import * as Button from '../../../components/UI/Forms/Button';
import { useStore } from '../../../store/store';
import requestsService from '../../../services/requestsService.service';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Toast from '../../../components/UI/General/Toast';
import Box from '../../../components/UI/General/Box';

const DepartmentAdd = () => {
  const setSteps = useStore((state) => state.setSteps);
  const { companyId, departmentId } = useParams();
  const [values, setValues] = useState();
  const [allUsers, setAllUsers] = useState(null);
  const [allBranches, setAllBranches] = useState(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const fullCompanies = useStore((state) => state.fullCompanies);
  const setFullCompanies = useStore((state) => state.setFullCompanies);

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      externalId: '',
      name: '',
      departmentManagerId: '',
      branches: [],
      users: [],
    },
    values,
  });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(true, { block: 'nearest' });
    }
  }, [containerRef.current]);

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('preOpenedDep', companyId);
    return () => {
      if (!location.pathname.includes('add') || !location.pathname.includes('edit')) {
        localStorage.removeItem('preOpenedDep');
      }
    };
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService.getBranches(companyId).then((r) => {
          if (r.data.status === 'success') {
            const activeBranches = r.data.data.filter((branch) => !!branch.active);
            setAllBranches(
              activeBranches.map((branch) => ({ label: branch.name, value: branch.id })),
            );
          }
        }),
        requestsService.getData(100, 0, { isEnabled: true, companyId: companyId }).then((r) => {
          const activeUsers = r.data.data.content.filter((user) => !!user.enabled);
          setAllUsers(
            activeUsers.map((user) => ({
              label: user.firstName + ' ' + user.lastName,
              value: user.username,
            })),
          );
        }),
      ]);
    };
    if (companyId !== undefined) {
      fetchData();
    }
  }, []);
  const onSubmit = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const { branches, users, departmentManager, ...other } = data;
    const { name, externalId } = other;
    let updBranches, updUsers, updDepartmentManager;
    if (Array.isArray(departmentManager) && departmentManager.length > 0) {
      updDepartmentManager = {
        departmentManagerId: departmentManager[0].value,
      };
    } else {
      updDepartmentManager = {
        departmentManagerId: departmentManager !== undefined ? departmentManager.value : '',
      };
    }
    if (branches && branches !== '' && Array.isArray(branches) && branches.length > 0) {
      updBranches = { branchesIds: branches.map((items) => items.value) };
    } else {
      updBranches = { branchesIds: [] };
    }
    if (users && Array.isArray(users) && users.length > 0) {
      updUsers = { userIds: users.map((items) => items.value) };
    } else {
      updUsers = { userIds: [] };
    }
    const newData = {
      name,
      externalId,
      ...updBranches,
      ...updUsers,
      ...updDepartmentManager,
    };
    if (departmentId !== undefined) {
      spmsServiceService
        .updateDepartment(companyId, departmentId, newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Department updated successfully',
            type: 'success',
          });
          navigate(-1);
        })
        .catch((error) => {
          setToast({
            opened: true,
            message: error.response.data.message,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createDepartment(companyId, newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Department created successfully',
            type: 'success',
          });
          setSteps();
          if (location.state.isSubsidiary && !location.state.companyHasDepartments) {
            spmsServiceService.getMetrics(companyId).then((res) => {
              const metrics = res.data.data;
              if (
                metrics.hasBranches &&
                metrics.hasDepartments &&
                metrics.hasGl &&
                !fullCompanies.find((company) => company.companyId === companyId)
              ) {
                setFullCompanies([
                  ...fullCompanies,
                  { companyId: companyId, companyName: location.state.companyName },
                ]);
              }
            });
          }
          navigate(-1);
        })
        .catch((error) => {
          setToast({
            opened: true,
            message: error.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  const onDiscard = () => {
    reset();
    navigate('/department-setup');
  };

  useEffect(() => {
    const fetchEditDepartment = async () => {
      await spmsServiceService.getDepartment(companyId, departmentId).then((r) => {
        const { branches, users: departmentUsers, departmentManager, ...others } = r.data;
        let updBranches, updUsers, updDepartmentManager;
        if (branches && branches.length > 0) {
          updBranches = branches.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        }
        if (departmentUsers && departmentUsers?.length > 0 && allUsers !== undefined) {
          updUsers = allUsers?.filter((item) => departmentUsers.includes(item.value));
        }
        if (departmentManager && departmentManager !== '' && allUsers !== undefined) {
          updDepartmentManager = allUsers?.filter((item) => item.value === departmentManager);
        }
        setValues({
          ...others,
          departmentManager: updDepartmentManager,
          branches: updBranches,
          users: updUsers,
        });
      });
    };
    if (companyId && departmentId && allUsers) {
      fetchEditDepartment();
    }
  }, [companyId, departmentId, allUsers]);

  return (
    <>
      <Box ref={containerRef} $radius={12} $mobExtend $asHolder>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.departmentForm}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="externalId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Department ID is required',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Maximum 15 characters',
                  },
                  validate: {
                    notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                    allowed: (v) =>
                      /^[A-Za-z0-9/-]*$/.test(v) || 'Only Alpha and Numerical characters',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Department ID"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Department ID"
                    $labelRequired
                    $tooltip="Unique identifier of the Department"
                    {...field}
                  />
                )}
              />
              {errors.externalId && <p className="error-message">{errors.externalId.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Department Name is required',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maximum 30 characters',
                  },
                  validate: {
                    notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                    allowed: (v) => /^[A-Za-z\s]*$/.test(v) || 'Only Alpha characters and space',
                    onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Department Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Name"
                    $labelRequired
                    $tooltip="Department Name"
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Department Manager" />
              <Controller
                name="departmentManager"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: '100 !important',
                      }),
                    }}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Department Manager"
                    options={allUsers || []}
                    hideSelectedOptions={false}
                  />
                )}
              />
            </div>
          </div>
          <br />
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Label>Branches</Label>
              <Controller
                name="branches"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Branches"
                    options={allBranches || []}
                    control={control}
                  />
                )}
              />
              <br />
              <Text>Select the branches the department is limited to.</Text>
            </div>
            <div className="inp-container">
              <Label>Users</Label>
              <Controller
                name="users"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Name"
                    options={allUsers || []}
                    control={control}
                  />
                )}
              />
              <br />
              <Text>Select the users the department is limited to.</Text>
            </div>
          </div>
          <div className={styles.backOverviewButton}>
            <div className={generalStyles.pageButtons}>
              <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid}>
                {departmentId !== undefined ? 'Update' : 'Save'}
              </Button.Main>
              <Button.Main $primary $style="gray" type="button" onClick={onDiscard}>
                Discard
              </Button.Main>
            </div>
          </div>

          {toast.opened === true ? (
            <Toast message={toast.message} opened={toast.opened} type={toast.type} />
          ) : null}
        </form>
      </Box>
    </>
  );
};

export default DepartmentAdd;
