import React, {useState} from 'react';
import styled from "styled-components";

const TooltipText = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-bottom: 4px;
  padding: 4px;
  background-color: #ecf8f8;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #333333;
  -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;
  @media(min-width: 768px){
    top: 0;
    left: 100%;
    bottom: auto;
    right: auto;
    width: max-content;
    max-width: ${({$maxWidth}) => $maxWidth ? $maxWidth : "240px"};
    margin-left: 6px;
    margin-top: -4px;
    margin-bottom: 0;
  }
`
const TooltipIconWrapper = styled.span`
  position: relative;
   &:before{
    content: "";
    display: ${({$show}) => $show ? "block" : "none"};
    position: absolute;    
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -6px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(69, 186, 188, 0.1);
    @media(min-width: 768px){
      top: 50%;
      left: 100%;
      width: 0;
      height: 0;
      margin-top: -6px;
      border-bottom: 6px solid transparent;
      border-right: 6px solid rgba(69, 186, 188, 0.1);
      border-top: 6px solid transparent;
    }
  }
`
const TooltipIcon = styled.span`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  -webkit-border-radius: 100%;-moz-border-radius: 100%;border-radius: 100%;
  background-color: rgba(69, 186, 188, 0.1);
  font-size: 10px;
  color: #333333;
`
const TooltipWrapper = styled.div`
  cursor: help;
  @media(min-width: 768px){
    position: relative;
    display: inline;
    z-index: 1;
  }
`

const Tooltip = ({text, icon, ...props}) => {
  const [show, setShow] = useState(false)
  return (
    <TooltipWrapper
      {...props}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onTouchStart={() => setShow(true)}
      onBlur={() => setShow(false)}
    >
      <TooltipIconWrapper $show={show}>{icon ? icon : <TooltipIcon>i</TooltipIcon>}</TooltipIconWrapper>
      {show && (
        <TooltipText $maxWidth={props.$maxWidth}>
          {text}
        </TooltipText>
      )}
    </TooltipWrapper>
  );
};
export default Tooltip;
