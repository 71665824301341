import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate, Navigate, useSearchParams} from 'react-router-dom';
import { useStore } from '../../store/store';
import SideBar from '../SideBar';
import * as Button from '../../components/UI/Forms/Button';
import Header from '../Header';
import style from '../../styles/companiesModal.module.css';
import styles from '../../styles/loggedInLayout.module.css';
import generalStyles from '../../styles/general.module.css';
import Modal from '../UI/Modal/Modal';
import Text from '../UI/Typography/Text';
import { Controller, useForm } from 'react-hook-form';
import Input from '../UI/Forms/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyBasicSchema } from '../../utils/validation/companySchema';
import Select from 'react-select';
import requestsServiceService from '../../services/requestsService.service';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../UI/General/Toast';
import {useSubscriptionAllowed} from "../../hooks/useSubscriptionAllowed";
import {Auth} from "aws-amplify";
import {useIdle} from "../../hooks/useIdle";

import smartLook from 'smartlook-client'
import {currencies} from "../../constants/currencies";

const industryOptions = [
  { value: 'Construction', label: 'Construction' },
  { value: 'Consulting Firms', label: 'Consulting Firms' },
  { value: 'Education', label: 'Education' },
  { value: 'Financial', label: 'Financial' },
  { value: 'FMCGs', label: 'FMCGs' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Logistics & Supplies', label: 'Logistics & Supplies' },
  { value: 'Retail & Ecommerce', label: 'Retail & Ecommerce' },
];

const LoggedInSuccess = () => {
  const user = useStore((state) => state.user);
  const countries = useStore((state) => state.countries);
  const updateUser = useStore((state) => state.updateUser);
  const customCompanies = useStore((state) => state.user?.customCompanies);
  const roles = useStore((state) => state.user?.roles);
  const setCompany = useStore((state) => state.setCompany);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const setFullCompanies = useStore((state) => state.setFullCompanies);
  const activeCompany = useStore((state) => state.activeCompany);
  const company = useStore((state) => state.company);
  const navigate = useNavigate();
  const fullCompanies = useStore((state) => state.fullCompanies);
  const fetchCountries = useStore((state) => state.fetchCountries);
  const fetchRegions = useStore((state) => state.fetchRegions);
  const setSteps = useStore((state) => state.setSteps);
  const stepsDone = useStore((state) => state.stepsDone);
  const [show, setShow] = useState(true);
  const [basicSetup, setBasicSetup] = useState(false);
  const { pathname } = useLocation();
  const [showSidebarMenu, setShowSidebarMenu] = useState(false)
  const [showHeaderMenu, setShowHeaderMenu] = useState(false)
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(companyBasicSchema),
  });
  const companyCountry = watch('country');

  useEffect(() => {
    if (!countries || !companyCountry) return undefined;
    const selectedCountryCurrency = countries.find(
      (country) => country.value === companyCountry.value,
    )?.currency;
    setValue('defaultCurrency', { label: selectedCountryCurrency, value: selectedCountryCurrency });
  }, [countries, companyCountry]);

  const createCurrencyOptions = () => {
    const createOptions = (optionArr) => optionArr.map((opt) => ({ value: opt, label: opt }));
    const countryCurrency = countries?.find(c => c?.value === companyCountry?.value)?.currency;
    return countryCurrency ? createOptions([...new Set([countryCurrency, ...currencies.map(c => c.value)])]) : currencies;
  };

  const updateCompanyDetails = useCallback(
    async (companyId) => {
      const res = await spmsServiceService.getCompany(companyId);
      const company = res.data.data;
      if (company.parent?.id) {
        setCompany(company.parent.id);
      } else {
        setCompany(companyId);
      }
      setActiveCompany(companyId);
      setSteps();
    },
    [setCompany, setSteps],
  );

  useEffect(() => {
    const checkFullSetupCompany = async () => {
      const setupCompaniesIds = [];
      await Promise.all(
        customCompanies.map(async (company) => {
          const res = await spmsServiceService.getMetrics(company.companyId);
          const metrics = res.data.data;
          if (metrics.hasBranches && metrics.hasDepartments && metrics.hasGl) {
            const { companyId, companyName } = company;
            setupCompaniesIds.push({ companyId, companyName });
          }
        }),
      );
      setFullCompanies(setupCompaniesIds);
      if (setupCompaniesIds.length === 1) {
        updateCompanyDetails(setupCompaniesIds[0].companyId);
      }
    };
    if (!activeCompany) {
      checkFullSetupCompany();
    } else {
      setCompany(customCompanies?.find(item => item.company.mainCompany === true)?.companyId);
    }
  }, [setCompany, customCompanies, updateCompanyDetails, activeCompany]);

  useEffect(() => {
    fetchCountries();
    fetchRegions();
  }, [fetchCountries, fetchRegions]);

  useEffect(() => {
    if (company !== null && stepsDone !== true) {
      setSteps();
    }
  }, [setSteps, stepsDone, company]);

  useEffect(() => {
    const selfSetup = sessionStorage.getItem('selfsetup');
    setBasicSetup(!selfSetup);
  }, []);

  const proceedSelfSetup = (data) => {
    setIsButtonsBlocked(true)
    navigate('/company-setup', { state: { data } });
    setShow(false);
    sessionStorage.setItem('selfsetup', 'true');
  };

  const onSubmit = async (data) => {
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      name: data.name,
      country: data.country.value,
      industry: data.industry.value,
      defaultCurrency: data.defaultCurrency.value,
    };
    spmsServiceService
      .createDummyCompany(requestBody)
      .then((res) => {
        updateCompanyDetails(res.data.data.id);
        setFullCompanies([{ companyId: res.data.data.id, companyName: res.data.data.name }]);
        if (user) {
          requestsServiceService
            .getUserDetailsById(user.id)
            .then((res) => {
              updateUser(res.data.data);
            })
            .catch((err) => console.log(err));
        }
        setToast({
          opened: true,
          message: 'Company created',
          type: 'success',
        });
      })
      .catch((err) => {
        console.log('err', err);
        setIsButtonsBlocked(false)
        setToast({
          opened: true,
          message: err.response?.data?.message,
          type: 'fail',
        });
      });
  };

  useEffect(() =>{
    setShowSidebarMenu(false)
    setShowHeaderMenu(false)
  }, [pathname])

  const accessSubscription = useSubscriptionAllowed()
  console.log(accessSubscription)

  const [trialDaysLeft, setTrialsDaysLeft] = useState(undefined)
  const [showExpireSoonModal, setShowExpireSoonModal] = useState(false)

  useEffect(() => {
    const today = new Date()
    const trialEnd = new Date(accessSubscription?.company?.trialEndsAt)
    const trialDifference = Math.round((trialEnd.getTime() - today.getTime()) / (1000 * 3600 * 24))
    setTrialsDaysLeft(trialDifference)
    if (trialDifference === 7 || trialDifference === 3 || accessSubscription?.company?.state === "EXPIRED"){
      setShowExpireSoonModal(true)
    }
  },[accessSubscription?.company?.trialEndsAt, accessSubscription?.company?.state])

  const countForSmartLook = useRef(0)
  useEffect(() => {
    if (user !== undefined && accessSubscription !== undefined && accessSubscription?.company?.state !== "EXPIRED" && countForSmartLook.current < 1){
      countForSmartLook.current = countForSmartLook.current + 1
      smartLook.identify(user.id, {
        "name": user.firstName + " " + user.lastName,
        "email": user.email,
        "package": accessSubscription?.plan?.name,
        "currency": accessSubscription?.company?.currency,
        "price": accessSubscription?.company?.total
      })
    }
  },[accessSubscription, user])

  const closeShowExpireSoon = () => {
    setShowExpireSoonModal(false)
    updateCompanyDetails(customCompanies?.find(item => item.company.mainCompany === true)?.companyId)
  }

  async function logout() {
    console.log('Logout');
    await Auth.signOut().then(() => {
      sessionStorage.removeItem('selfsetup');
    });
  }

  const idle = useIdle(60*60*1000) // 1 hour

  useEffect(() => {
    if (!idle) return
    logout()
  },[idle])

  useEffect(() => {
    const redirectPath = sessionStorage.getItem('redirectPath');
    if (pathname === redirectPath){
      sessionStorage.removeItem('redirectPath');
    }
  },[pathname])

  return (
    <div className={styles.wrapper}>
      {(stepsDone === true || roles?.find((role) => role.role.name === 'VENDOR')) && accessSubscription?.company?.state !== "EXPIRED" ? <SideBar showMenu={showSidebarMenu} accessSubscription={accessSubscription} /> : null}
      <main className={styles.content}>
        <Header setShowSidebarMenu={setShowSidebarMenu} setShowHeaderMenu={setShowHeaderMenu} showHeaderMenu={showHeaderMenu} showSidebarMenu={showSidebarMenu} />
        <section className={styles.contentInner}>
          <Outlet />
        </section>

        {(trialDaysLeft === 7 || trialDaysLeft === 3 || accessSubscription?.company?.state === "EXPIRED") ? (
          <Modal
            $maxWidth={'500px'}
            $show={showExpireSoonModal}
            $close={closeShowExpireSoon}
            $noCloseSign={accessSubscription?.company?.state === "EXPIRED"}
            $title={accessSubscription?.company?.state === "EXPIRED" ? "Trial expired" : "Trial expires soon"}
            $radius={12}
            $blur
          >
            <div className={generalStyles.modal + " " +styles.expireModalContent}>
              {roles?.find((role) => role.role.name === 'CLIENT_ADMIN') && (
                <>
                  {trialDaysLeft === 7 && <Text type="body-1">You have 7 days left on your trial. Click here to subscribe for a suitable plan.</Text>}
                  {trialDaysLeft === 3 && <Text type="body-1">You have 3 days left on your trial. Click here to subscribe for a suitable plan.</Text>}
                  {accessSubscription?.company?.state === "EXPIRED" && <Text type="body-1">Your trial period has expired. Please subscribe to a suitable plan to continue using the system.</Text>}
                </>
              )}
              {!roles.some((role) => role.role.name.includes('CLIENT_ADMIN') || role.role.name.includes('SUPER_ADMIN') || role.role.name.includes('VENDOR')) && (
                <>
                  {trialDaysLeft === 7 && <Text type="body-1">You have 7 days left on your trial. Please contact your system administrator to subscribe to a plan.</Text>}
                  {trialDaysLeft === 3 && <Text type="body-1">You have 3 days left on your trial. Please contact your system administrator to subscribe to a plan.</Text>}
                  {accessSubscription?.company?.state === "EXPIRED" && <Text type="body-1">
                    Dear {user.firstName}<br/>
                    Your trial period has expired.<br/>
                    Please contact your system administrator to subscribe to a plan.
                  </Text>}
                </>
              )}
              {roles?.find((role) => role.role.name === 'CLIENT_ADMIN') && (
                <Button.Main
                  $primary
                  $style="pink"
                  onClick={() => (closeShowExpireSoon(), navigate('/accounts-billing/subscription'))}
                >
                  Subscribe
                </Button.Main>
              )}
              {(roles.find((role) => role.role.name === 'CLIENT_ADMIN') && accessSubscription?.company?.state === "EXPIRED") && (
                <>
                  <Text type="body-1">Your data will be safe for the next 30 days. If you feel you need more trial period, please contact support.</Text>
                  <a href="mailto:support.yopmail.com">
                    <Button.Main $primary $style="pink">subscompanytest@yopmail.com</Button.Main>
                  </a>
                </>
              )}
              {(!roles.find((role) => role.role.name === 'CLIENT_ADMIN') && accessSubscription?.company?.state === "EXPIRED") && (
                <Button.Main
                  $primary
                  $style="pink"
                  onClick={() => (closeShowExpireSoon(), logout())}
                >
                  Logout
                </Button.Main>
              )}
            </div>
          </Modal>
        ) : null}

        {company === null && (trialDaysLeft !== 7 && trialDaysLeft !== 3 && accessSubscription?.company?.state !== "EXPIRED") && (
          <>
            {fullCompanies.length > 0 && fullCompanies.length !== 1 && (
              <Modal
                $weight={600}
                $color={'#D9D9D9'}
                $maxWidth={'600px'}
                $show={true}
                $title="Company"
                $radius={12}
                $blur
              >
                <div className={style.modalContent}>
                  <Text type="subtitle" weight={500}>
                    Please select company you would like to enter.
                  </Text>

                  <div className={style.companiesList}>
                    {fullCompanies.map((customCompany) => (
                      <Button.Main
                        $primary
                        key={customCompany.companyId}
                        $style="pink"
                        onClick={() => {updateCompanyDetails(customCompany.companyId)}}
                      >
                        <span>{customCompany.companyName}</span>
                      </Button.Main>
                    ))}
                  </div>
                </div>
              </Modal>
            )}
            {!customCompanies.length &&
              basicSetup &&
              !roles.find((role) => role.role.name === 'VENDOR') && (
                <Modal
                  $weight={600}
                  $color={'#D9D9D9'}
                  $maxWidth={'664px'}
                  $show={show}
                  $title="Basic Company Setup"
                  $radius={12}
                  $blur
                  $noOverflow
                >
                  <div className={`${style.modalContent} ${style.basicCompanyModal}`}>
                    <div className={style.inputFields}>
                      <div className="inp-container">
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Input
                              type="text"
                              $iconName="userProfile"
                              placeholder="Enter Legal Name *"
                              className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                              id={field.name}
                              {...field}
                            />
                          )}
                        />
                        {errors.name && <p className="error-message">{errors.name?.message}</p>}
                      </div>
                      <div className="inp-container">
                        <Controller
                          name="country"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="react-select"
                              filterOption={(option, inputValue) =>
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              className={
                                errors.hasOwnProperty(field.name)
                                  ? 'react-select-container error'
                                  : 'react-select-container'
                              }
                              isSearchable={true}
                              placeholder="Select Country of Incorporation *"
                              options={countries}
                            />
                          )}
                        />
                        {errors.country && (
                          <p className="error-message">{errors.country.value.message}</p>
                        )}
                      </div>
                      <div className="inp-container">
                        <Controller
                          name="defaultCurrency"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className={
                                errors.hasOwnProperty(field.name)
                                  ? 'react-select-container error'
                                  : 'react-select-container'
                              }
                              classNamePrefix="react-select"
                              isSearchable={false}
                              placeholder="Select Currency *"
                              options={createCurrencyOptions()}
                            />
                          )}
                        />
                        {errors.defaultCurrency && (
                          <p className="error-message">{errors.defaultCurrency?.value.message}</p>
                        )}
                      </div>
                      <div className="inp-container">
                        <Controller
                          name="industry"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className={
                                errors.hasOwnProperty(field.name)
                                  ? 'react-select-container error'
                                  : 'react-select-container'
                              }
                              classNamePrefix="react-select"
                              isSearchable={false}
                              placeholder="Select Industry *"
                              options={industryOptions}
                            />
                          )}
                        />
                        {errors.industry && (
                          <p className="error-message">{errors.industry?.value.message}</p>
                        )}
                      </div>
                    </div>
                    <div className={style.buttons}>
                      <Button.Main $primary $style="pink" onClick={handleSubmit(onSubmit)} disabled={!isValid || isButtonsBlocked}>
                        Proceed with Dummy Data
                      </Button.Main>
                      <Button.Main $primary $style="gray" onClick={handleSubmit(proceedSelfSetup)} disabled={!isValid || isButtonsBlocked}>
                        Proceed with Self-Setup
                      </Button.Main>
                    </div>
                  </div>
                </Modal>
              )}
            {toast.opened === true ? (
              <Toast message={toast.message} opened={toast.opened} type={toast.type} />
            ) : null}
          </>
        )}
      </main>
    </div>
  );
};

const LoggedIn = () => {
  const location = useLocation();
  const [search] = useSearchParams()
  const user = useStore((state) => state.user);
  let toLocation
  if (search.get('source') !== null) {
    toLocation = location
  } else {
    toLocation = ""
  }
  return user !== null ? <LoggedInSuccess /> : <Navigate to={"/login"} replace state={{ from: toLocation }} />;
}

export default LoggedIn;
