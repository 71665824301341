import React, {useState} from 'react';
import Text from "../../components/UI/Typography/Text";
import ProfileAccount from "../../components/Admins/Profile/ProfileAccount";
import ProfileLegalEntity from "../../components/Admins/Profile/ProfileLegalEntity";
import ProfilePassword from "../../components/Admins/Profile/ProfilePassword";
import ProfileHoliday from "../../components/Admins/Profile/ProfileHoliday";
import TabsSlider from "../../components/UI/General/TabsSlider";

const Profile = () => {
  const [tab, setTab] = useState("account")
  return (
    <>
      <Text type="h2" weight={600}>My Profile</Text>
      <TabsSlider>
        <span onClick={() => setTab("account")}>Account</span>
        {/*<span onClick={() => setTab("entity")}>Legal Entity Setup</span>*/}
        {/*<span onClick={() => setTab("holiday")}>Holiday Settings</span>*/}
        <span onClick={() => setTab("password")}>Change Password</span>
      </TabsSlider>
      {tab === "account" && <ProfileAccount/>}
      {/*{tab === "entity" && <ProfileLegalEntity/>}*/}
      {/*{tab === "holiday" && <ProfileHoliday/>}*/}
      {tab === "password" && <ProfilePassword/>}
    </>
  );
};

export default Profile;
