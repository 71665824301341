import React, {useEffect, useState} from 'react';
import style from '../../styles/log_reg.module.css';
import Text from '../UI/Typography/Text';
import * as Button from '../UI/Forms/Button';
import {Controller, useForm} from 'react-hook-form';
import requestsServiceService from '../../services/requestsService.service';
import Toast from '../UI/General/Toast';
import {InputCode} from "../UI/Forms/Input";
import {useNavigate} from "react-router-dom";

const Verify = ({ username }) => {
  const navigate = useNavigate();
  const {handleSubmit, control, setFocus, formState: {isValid, isDirty}} = useForm({mode: "onChange"});
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [timer, setTimer] = useState(undefined)

  useEffect(()=>{
    setFocus('code.0.number');
  },[setFocus])

  const confirmUser = ({code}) => {
    setToast((item) => ({...item, opened: false}));
    const newCode = code.map(el=>el.number).join('')
    requestsServiceService.confirmUser(username, newCode).then(r => {
      if (r.status === 200){
        setToast({
          opened: true,
          message: "Your account confirmed successfully",
          type: 'success',
          cb: () => navigate('/login')
        });
      }
    }).catch(err => {
      console.log(err)
      setToast({
        opened: true,
        message: err.response.data.message !== undefined ? err.response.data.message : err,
        type: 'fail',
      });
    })
  }

  const resendConfirmation = () => {
    console.log("not send")
    if ( timer !== null) return
    console.log("send")
    setToast((item) => ({...item, opened: false}));
    requestsServiceService.resendConfirmation(username).then((res) => {
      if (res.status === 200) {
        console.log(res);
        setToast({
          opened: true,
          message: 'Success! Please check your inbox for an email from us.',
          type: 'success',
        });
      }
    })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message !== undefined ? err.message : err,
          type: 'fail',
        });
      });
  };

  const userNameHidden = username.replace(/(.{2})(.*)(?=@)/,
    function(gp1, gp2, gp3) {
      for(let i = 0; i < gp3.length; i++) {
        gp2+= "*";
      } return gp2;
    }
  );

  useEffect(() => {
    const countDownDate = new Date(Date.now()+60*1000);
    const x = setInterval(function() {
      const now = new Date().getTime();
      let distance = countDownDate - now;
      const minutes = `${Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))}`.padStart(2, "0");
      const seconds = `${Math.floor((distance % (1000 * 60)) / 1000)}`.padStart(2, "0");
      setTimer(() => minutes+':'+seconds)
      if (distance < 0) {
        clearInterval(x);
        setTimer(null)
      }
    }, 1000);
    return () => clearInterval(x)
  },[])

  return (
    <>
      <div className={style.authHeader + ' ' + style.cnpHeader}>
        <img src="assets/img/login_register/verify.svg" alt="verify account" />
        <Text type="h2" weight={600}>
          Account Verification
        </Text>
        <Text type="subtitle">
          An email with your verification code has been sent to <Text weight={600}>{userNameHidden}</Text>
        </Text>
      </div>
      <form onSubmit={handleSubmit(confirmUser)} className={style.authForm + ' ' + style.authFormSmall+ ' ' + style.verificationForm}>
        <Text type={'body-1'} className={style.verificationCodeLabel}>Your Verification Code</Text>
        <div className={style.inputsCode}>
          {[1,2,3,4,5,6].map((el,i) => (
            <Controller
              key={i}
              name={`code.${i}.number`}
              control={control}
              rules={{
                required:true,
                minLength:1,
                maxLength:1,
                pattern:/^[0-9]*$/,
              }}
              defaultValue=""
              render={({field}) => (
                <InputCode
                  {...field}
                  type="text"
                  maxLength={1}
                  onChange={(e) => {
                    const res = e.target.value.replace(/[^0-9]/g, '')
                    field.onChange(res)
                    if (res !== ''){
                      setFocus(`code.${i+1}.number`)
                    }else{
                      setFocus(`code.${i-1}.number`)
                    }
                  }}
                />
              )}
            />
          ))}
        </div>
        <div className={style.timer}>
          <span>{timer}</span>
          {timer === null ? <span className="link" onClick={() => resendConfirmation()}>Resend</span> : null}
        </div>
        <Button.Main
          disabled={!isDirty || !isValid}
          $primary
          $full
          $style="pink"
          $iconRight
          type="submit"
        >
          Verify Account
        </Button.Main>
      </form>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Verify;
