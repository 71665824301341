import React, { useEffect } from 'react';
import styles from '../../../styles/companySetup.module.css';
import generalStyles from '../../../styles/general.module.css';
import * as Button from '../../UI/Forms/Button';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../UI/Forms/Input';
import SpmsService from '../../../services/spmsService.service';
import Label from '../../UI/Forms/Label';
import CountryAndRegion from '../../UI/Forms/CountryAndRegion';
import PhoneInput from 'react-phone-number-input';
import branchSchema from '../../../utils/validation/branchSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '../../../store/store';

const AddNewBranch = ({
  companyDetails,
  branchData,
  onClose,
  refetchBranches,
  countries,
  mode,
  setToast,
  countryCode,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(branchSchema),
    mode: 'onChange',
  });
  const fullCompanies = useStore((state) => state.fullCompanies);
  const setFullCompanies = useStore((state) => state.setFullCompanies);

  useEffect(() => {
    if (branchData?.country) {
      reset({
        ...branchData,
        country: {
          label: branchData?.country ?? '',
          value: branchData?.country ?? '',
          code: countries.find((country) => country.value === branchData?.country)?.code ?? '',
        },
        state: {
          label: branchData?.state ?? '',
          value: branchData?.state ?? '',
        },
      });
    }
  }, [branchData, reset]);

  async function submitBranch(data) {
    data = {
      name: data.name,
      id: branchData?.id,
      address: {
        addressLine2: data.address_two,
        streetAddress: data.address_one,
        city: data.city,
        state: data.state.value,
        zipCode: data.zip,
        country: data.country.value,
      },
      email: data.email,
      phoneNumber: data.phoneNumber,
    };
    setToast((item) => ({ ...item, opened: false }));
    if (branchData?.id == null) {
      SpmsService.createBranch(companyDetails.id, data)
        .then((res) => {
          if (res.data.status === 'success') {
            setToast({
              opened: true,
              message: 'Branch has been created',
              type: 'success',
            });

            onClose();
            if (companyDetails.subsidiary && !companyDetails.branches?.length) {
              SpmsService.getMetrics(companyDetails.id).then((res) => {
                const metrics = res.data.data;
                console.log(
                  metrics.hasBranches,
                  metrics.hasDepartments,
                  metrics.hasGl,
                  !fullCompanies.find((company) => company.companyId === companyDetails.id),
                );
                if (
                  metrics.hasBranches &&
                  metrics.hasDepartments &&
                  metrics.hasGl &&
                  !fullCompanies.find((company) => company.companyId === companyDetails.id)
                ) {
                  setFullCompanies([
                    ...fullCompanies,
                    { companyId: companyDetails.id, companyName: companyDetails.name },
                  ]);
                }
              });
            }
            refetchBranches();
          }
        })
        .catch((error) => {
          console.log('error', error);
          setToast({
            opened: true,
            message: error.response.data.message.toString(),
            type: 'fail',
          });
        });
    } else {
      SpmsService.updateBranch(companyDetails.id, branchData?.id, data)
        .then((res) => {
          setToast({
            opened: true,
            message: 'Branch has been updated',
            type: 'success',
          });
          refetchBranches();
          onClose();
        })
        .catch((error) =>
          setToast({
            opened: true,
            message: 'Something went wrong',
            type: 'success',
          }),
        );
    }
  }

  return (
    <form className={styles.addNewBranch} onSubmit={handleSubmit(submitBranch)}>
      <div className={mode === 'edit' ? styles.editBranch : styles.newBranch}>
        <div className={styles.form}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Branch Name"
                    $labelRequired
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Branch Name"
                    $tooltip="Company branch name"
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    $labelRequired
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Email Address"
                    $tooltip="Primary branch email address"
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="error-message">{errors.email?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Phone Number" $isRequired $tooltipText="Primary branch phone number" />
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <PhoneInput
                    defaultCountry={countryCode}
                    limitMaxLength={true}
                    {...field}
                    placeholder="Enter Phone Number"
                    international
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                  />
                )}
              />
              {errors.phoneNumber && <p className="error-message">{errors.phoneNumber?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="address_one"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Suite/Building Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Suite Number/Building Name/Office Park"
                    {...field}
                  />
                )}
              />
              {errors.address_one && <p className="error-message">{errors.address_one?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="address_two"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Street Address"
                    $labelRequired
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Street Address"
                    $tooltip="Street name and number"
                    {...field}
                  />
                )}
              />
              {errors.address_two && <p className="error-message">{errors.address_two?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter City *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="City"
                    $labelRequired
                    $tooltip="City the branch is located in"
                    {...field}
                  />
                )}
              />
              {errors.city && <p className="error-message">{errors.city?.message}</p>}
            </div>
            <CountryAndRegion
              countryFieldName="country"
              regionFieldName="state"
              control={control}
              isDisable={false}
              countries={countries}
              errors={errors}
              countryTooltip="Country the branch is located in"
              regionTooltip="Region of the branch"
            />
            <div className="inp-container">
              <Controller
                name="zip"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Postal Code"
                    $labelRequired
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Postal Code"
                    $tooltip="Postal Code of the branch"
                    {...field}
                  />
                )}
              />
              {errors.zip && <p className="error-message">{errors.zip?.message}</p>}
            </div>
            <Controller
              name="id"
              control={control}
              defaultValue={companyDetails?.id}
              render={({ field }) => <input type="hidden" {...field} />}
            />
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid}>
              {mode === 'edit' ? 'Update' : 'Save'}
            </Button.Main>
            <Button.Main
              $primary
              $style={!isDirty || !isValid ? 'pink' : 'gray'}
              type="button"
              onClick={onClose}
            >
              Discard
            </Button.Main>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewBranch;
