import React, { useMemo } from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import generalStyles from '../../../../styles/general.module.css';
import DataTableBase from '../../../UI/General/DataTableBase';
import Text from '../../../UI/Typography/Text';
import moment from 'moment';
import spmsServiceService from '../../../../services/spmsService.service';
import { downloadExternal } from '../../../../utils/downloadExternal';
import Icon from '../../../UI/General/Icon';
import * as Button from '../../../UI/Forms/Button.jsx';

const QuotesTab = ({ data, deleteDocument }) => {
  const getDocument = (key, name) => {
    spmsServiceService.getAttachmentsByKey(key).then((res) => {
      const url = res.data.data;
      const fileType = name.split('.').at(-1);
      downloadExternal(url, fileType);
    });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Date Created',
        selector: (row) => row?.dateUploaded,
        format: (row) => moment(row?.dateUploaded).format('DD/MM/YYYY'),
        cell: row => <span data-content={"Date Created"} className={generalStyles.tableValue}><span>{moment(row?.dateUploaded).format('DD/MM/YYYY') ?? "-"}</span></span>,
      },
      {
        name: 'Created By',
        selector: (row) => <p>{row.createdBy?.name}</p>,
        cell: row => <span data-content={"Created By"} className={generalStyles.tableValue}><span>{row?.createdBy?.name ?? "-"}</span></span>,
      },
      {
        name: 'File',
        cell: (row) => (
          <span data-content={"File"} className={generalStyles.tableValue}>
            <span onClick={() => getDocument(row.key, row.name)} className={styles.linkDownload}>
              <Text weight={700} type={'body-1'} className={styles.linkHighlighted}>
                {row.name}
              </Text>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12V9.5C15 9.33424 14.9342 9.17527 14.8169 9.05806C14.6997 8.94085 14.5408 8.875 14.375 8.875C14.2092 8.875 14.0503 8.94085 13.9331 9.05806C13.8158 9.17527 13.75 9.33424 13.75 9.5V12C13.75 12.1658 13.6842 12.3247 13.5669 12.4419C13.4497 12.5592 13.2908 12.625 13.125 12.625H1.875C1.70924 12.625 1.55027 12.5592 1.43306 12.4419C1.31585 12.3247 1.25 12.1658 1.25 12V9.5C1.25 9.33424 1.18415 9.17527 1.06694 9.05806C0.949731 8.94085 0.79076 8.875 0.625 8.875C0.45924 8.875 0.300269 8.94085 0.183058 9.05806C0.0658481 9.17527 0 9.33424 0 9.5V12C0 12.4973 0.197544 12.9742 0.549175 13.3258C0.900806 13.6775 1.37772 13.875 1.875 13.875H13.125C13.6223 13.875 14.0992 13.6775 14.4508 13.3258C14.8025 12.9742 15 12.4973 15 12ZM11.0125 8.7375L7.8875 11.2375C7.77716 11.3247 7.64063 11.3721 7.5 11.3721C7.35937 11.3721 7.22284 11.3247 7.1125 11.2375L3.9875 8.7375C3.87364 8.63002 3.80426 8.48372 3.7931 8.32753C3.78195 8.17135 3.82982 8.01667 3.92725 7.8941C4.02468 7.77152 4.16457 7.68998 4.31924 7.66562C4.47392 7.64125 4.6321 7.67582 4.7625 7.7625L6.875 9.45V0.75C6.875 0.58424 6.94085 0.425269 7.05806 0.308058C7.17527 0.190848 7.33424 0.125 7.5 0.125C7.66576 0.125 7.82473 0.190848 7.94194 0.308058C8.05915 0.425269 8.125 0.58424 8.125 0.75V9.45L10.2375 7.7625C10.3004 7.70309 10.375 7.6574 10.4565 7.62833C10.5381 7.59925 10.6247 7.58742 10.711 7.59359C10.7974 7.59976 10.8815 7.62378 10.958 7.66415C11.0346 7.70452 11.1019 7.76035 11.1558 7.8281C11.2096 7.89585 11.2488 7.97405 11.2709 8.05773C11.2929 8.14142 11.2974 8.22878 11.2839 8.31427C11.2704 8.39976 11.2394 8.48153 11.1926 8.55438C11.1459 8.62723 11.0846 8.68959 11.0125 8.7375Z"
                  fill="#C85D9F"
                />
              </svg>
            </span>
          </span>
        ),
      },
      {
        name: 'Action',
        button: true,
        omit: deleteDocument === undefined,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Button.Action
              $variant="circle"
              $style="lightGrayishCyan"
              $width={40}
              $height={40}
              onClick={() => deleteDocument(row.key)}
              type="button"
            >
              <Icon $width={20} $height={20} $icon="delete" $color="#F24638" />
            </Button.Action>
          </div>
        ),
      },
    ],
    [deleteDocument],
  );

  return <DataTableBase data={data} columns={columns} pagination={false} />;
};

export default QuotesTab;
