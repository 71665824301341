import { Link, useNavigate } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import styles from '../../styles/reports.module.css';
import generalStyles from '../../styles/general.module.css';
import { useForm } from 'react-hook-form';
import { useMemo, useState } from 'react';
import Box from '../../components/UI/General/Box.jsx';
import Icon from '../../components/UI/General/Icon.jsx';
import Status from '../../components/Admins/Reports/Status.jsx';
import Filters from '../../components/Admins/Reports/Filters.jsx';
import { ProgressBarFill, ProgressBarWrapper } from '../../components/UI/General/ProgressBar.jsx';
import moment from 'moment';
import useGetReportsData from '../../components/Admins/Reports/helpers/useGetReportsData.jsx';
import Modal from '../../components/UI/Modal/Modal.jsx';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote.jsx';

const BudgetModal = ({ budgetToView, closeModal }) => {
  const data = [
    { name: 'Name', value: budgetToView?.name },
    { name: 'Company Name:', value: budgetToView?.companyName },
    { name: 'Start Date:', value: budgetToView?.startDate },
    { name: 'End Date:', value: budgetToView?.endDate },
    { name: 'Total Allocated', value: `$ ${budgetToView?.allocatedAmount ?? 0}` },
    { name: 'Total Used', value: `$ ${budgetToView?.totalUsed ?? 0}` },
    { name: 'Status', value: budgetToView?.status },
  ];
  return (
    <Modal $show={!!budgetToView} $radius={4}>
      <div className={generalStyles.mainDiv}>
        <div className={generalStyles.textIconDiv}>
          <Text type="h4" className={generalStyles.textField}>
            {budgetToView?.name}
          </Text>
        </div>
      </div>
      <div className={generalStyles.modalContainer}>
        <div className={styles.modalData}>
          {data.map((budget) => (
            <div key={budget.name} className={styles.modalRow}>
              <Text weight={600}>{budget.name}</Text>
              <span>{budget?.value}</span>
            </div>
          ))}
        </div>
        <div className={generalStyles.modalButtons}>
          <Button.Main onClick={closeModal} $primary $style="gray" type="submit" form="cForm">
            Close
          </Button.Main>
        </div>
      </div>
    </Modal>
  );
};

const BudgetReports = () => {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
    getValues,
    clearErrors,
  } = useForm({
    mode: 'onChange',
  });
  const [budgetToView, setBudgetToView] = useState(null);

  const navigate = useNavigate();
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const { getReports, getExport } = useGetReportsData(getValues(), fetchTrigger);

  const formatDate = (date) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const handleLink = (status, id, companyId) => {
    return status === 'APPROVED'
      ? `/budgets/${companyId}/details/${id}`
      : status === 'DRAFT'
      ? `/budgets/${companyId}/edit/${id}`
      : status === 'PENDING'
      ? `/budgets/${companyId}/pending-approval/${id}`
      : `/budgets/overview`;
  };
  const columns = useMemo(
    () => [
      {
        name: 'Budget Name',
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <Link className={styles.nameLink} to={handleLink(row.status, row.id, row.companyId)}>
            {row.name}
          </Link>
          // <Text className={styles.nameLink} onClick={() => setBudgetToView(row)}>
          //   {row.name}
          // </Text>
        ),
      },
      {
        name: 'Start Date',
        selector: (row) => row.startDate,
        sortable: true,
        cell: (row) => <div>{formatDate(row.startDate)}</div>,
      },
      {
        name: 'End Date',
        selector: (row) => row.endDate,
        sortable: true,
        cell: (row) => <div>{formatDate(row.endDate)}</div>,
      },
      {
        name: 'Total Allocated',
        selector: (row) => `$ ${row.allocatedAmount ?? 0}`,
        sortable: true,
      },
      {
        name: 'Total Used',
        selector: (row) => row.spentAmount,
        cell: (row) => (
          <div className={styles.totalUsed}>
            <span>{row.totalUsed ?? 0} %</span>
            <ProgressBarWrapper width={104}>
              <ProgressBarFill progress={row.totalUsed ?? 0} />
            </ProgressBarWrapper>
          </div>
        ),
      },
      {
        name: 'Branch',
        selector: (row) => row?.branchName,
      },
      {
        name: 'Department',
        selector: (row) => row?.department,
        wrap: true,
      },
      {
        name: 'Company',
        selector: (row) => row.companyName,
      },
      {
        name: 'Status',
        selector: (row) => row.department,
        cell: (row) => <Status status={row.status} />,
      },
    ],
    [],
  );

  return (
    <div>
      <Text className={styles.sectionTitle} type="h2" weight={600}>
        Budget Reports
      </Text>
      <Box $radius={12} className={styles.container}>
        <form className={styles.filterForm}>
          <Filters
            control={control}
            errors={errors}
            trigger={trigger}
            watch={watch}
            clearErrors={clearErrors}
            setValue={setValue}
            filters={['department', 'gl']}
            page="budgets"
          />
          <div className={generalStyles.pageButtons}>
            <Button.Main
              onClick={() => setFetchTrigger((fetchTrigger) => !fetchTrigger)}
              $primary
              $style="pink"
              type="button"
            >
              Submit
            </Button.Main>
            <Button.Main
              onClick={() => navigate('/reports/overview')}
              $primary
              $style="gray"
              type="button"
            >
              Back to Overview
            </Button.Main>
          </div>
        </form>
      </Box>
      <br />
      <br />
      <Box $radius={12} className={styles.container}>
        <div>
          <div className={styles.exportBtnContainer}>
            <Button.Main
              $primary
              $style="pink"
              onClick={() => getExport('Budget Reports')}
              $iconLeft
            >
              <Icon $width={20} $height={20} $icon="export" />
              <Text weight={500} type="subtitle">
                Export
              </Text>
            </Button.Main>
          </div>
          <div className={styles.reportsTable}>
            <DataTableBaseRemote
              columns={columns}
              separatePagination
              fetchData={getReports}
              selectableRows={false}
            />
          </div>
        </div>
      </Box>
      <BudgetModal budgetToView={budgetToView} closeModal={() => setBudgetToView(null)} />
    </div>
  );
};

export default BudgetReports;
