import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styles from '../styles/loggedInLayout.module.css';
import Dropdown from './UI/General/Dropdown';
import Text from './UI/Typography/Text';
import { useStore } from '../store/store';
import Select from 'react-select';
import { useShallow } from 'zustand/react/shallow';
import Notifications from './Notifications';
import { useSubscriptionAllowed } from '../hooks/useSubscriptionAllowed';
import UpgradePlan from './shared/UpgradePlan';

function Header({ setShowSidebarMenu, setShowHeaderMenu, showHeaderMenu, showSidebarMenu }) {
  const showCompanyDropdown = useStore((state) => state.showCompanyDropdown);
  const user = useStore((state) => state.user);
  const stepsDone = useStore(useShallow((state) => state.stepsDone));
  const fullCompanies = useStore((state) => state.fullCompanies);
  const activeCompany = useStore((state) => state.activeCompany);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const currentSubscription = useSubscriptionAllowed();

  async function logout() {
    console.log('Logout');
    await Auth.signOut().then(() => {
      sessionStorage.removeItem('selfsetup');
    });
  }

  const updateCompanyDetails = (companyId) => {
    setActiveCompany(companyId);
  };

  const isVendor = user?.roles?.map((r) => r?.role?.name).includes('VENDOR');

  return (
    <header className={styles.header}>
      <label
        className={
          !showSidebarMenu ? styles.mobileMenu : styles.mobileMenu + ' ' + styles.mobileMenuOpened
        }
        onClick={() => setShowSidebarMenu((state) => !state)}
      >
        <span />
        <span />
        <span />
      </label>
      <Link to="/dashboard" className={styles.mobileLogo}>
        <img src="/assets/img/general/logo_loggedin.svg" alt="Poms" />
      </Link>
      <label
        className={
          !showHeaderMenu
            ? styles.mobileMenu + ' ' + styles.right
            : styles.mobileMenu + ' ' + styles.right + ' ' + styles.mobileMenuOpened
        }
        onClick={() => setShowHeaderMenu((state) => !state)}
      >
        <span />
        <span />
        <span />
      </label>
      <div
        className={
          !showHeaderMenu ? styles.headerInner : styles.headerInner + ' ' + styles.headerInnerOpened
        }
      >
        {stepsDone === false && !isVendor && (
          <div className={styles.headerWidgetLogo}>
            <Link to="/dashboard">
              <img src="/assets/img/general/logo_loggedin.svg" alt="Poms" />
            </Link>
          </div>
        )}
        {activeCompany && showCompanyDropdown !== false && (
          <Select
            defaultValue={{ label: activeCompany?.name, value: activeCompany?.id }}
            onChange={(e) => updateCompanyDetails(e.value)}
            classNamePrefix="react-select"
            options={fullCompanies.map((item) => ({
              label: item.companyName,
              value: item.companyId,
            }))}
          />
        )}
        <div className={styles.headerBlock}>
          <Notifications />
        </div>
        <div className={styles.headerBlock}>
          <Dropdown collapsible>
            <Dropdown.Header $activeClass={styles.active}>
              <div className={styles.headerUser}>
                <div className={styles.headerAvatar}>
                  {user.profileImage !== null ? (
                    <img
                      src={'data:image/jpg;base64,' + user.profileImage}
                      alt={user.firstName + ' ' + user.lastName}
                      title={user.firstName + ' ' + user.lastName}
                    />
                  ) : (
                    <Text weight={600} type="body-1">
                      {user.firstName.charAt(0) + user.lastName.charAt(0)}
                    </Text>
                  )}
                </div>
              </div>
            </Dropdown.Header>
            <Dropdown.Body>
              <div className={styles.headerDrop}>
                <div className={styles.headerDropSection}>
                  <Text weight={600} type="body-2">
                    PROFILE
                  </Text>
                  <div className={styles.headerDropUserInfo}>
                    <div className={styles.headerAvatar}>
                      {user.profileImage !== null ? (
                        <img
                          src={'data:image/jpg;base64,' + user.profileImage}
                          alt={user.firstName + ' ' + user.lastName}
                          title={user.firstName + ' ' + user.lastName}
                        />
                      ) : (
                        <Text weight={600} type="body-1">
                          {user.firstName.charAt(0) + user.lastName.charAt(0)}
                        </Text>
                      )}
                    </div>
                    <div>
                      <Text type="body-2" weight={500}>
                        {user && `${user.firstName} ${user.lastName}`}
                      </Text>
                      <Text type="body-3" weight={500}>
                        {user && user.email}
                      </Text>
                    </div>
                  </div>
                  <Link to="/users/profile">
                    <Text type="body-2" weight={500}>
                      Manage Account
                    </Text>
                  </Link>
                </div>
                {!!fullCompanies?.length ? (
                  <>
                    <div className={styles.headerDropSection}>
                      <Text weight={600} type="body-2">
                        ACCOUNTS & BILLING
                      </Text>
                      <div>
                        <Link to="/accounts-billing/overview">
                          <Text type="body-2" weight={500}>
                            Overview
                          </Text>
                        </Link>
                        <Link to="/accounts-billing/subscription">
                          <Text type="body-2" weight={500}>
                            Subscription
                          </Text>
                        </Link>
                        <Link to="/accounts-billing/overview">
                          <Text type="body-2" weight={500}>
                            Billing Information
                          </Text>
                        </Link>
                        <Link to="/accounts-billing/invoices">
                          <Text type="body-2" weight={500}>
                            Invoices
                          </Text>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.headerDropSection}>
                      <Text weight={600} type="body-2">
                        SYSTEM
                      </Text>
                      {currentSubscription?.plan?.systemAuditLogEnabled ? (
                        <Link to="/system-audit">
                          <Text type="body-2" weight={500}>
                            System Audit
                          </Text>
                        </Link>
                      ) : (
                        <div className={styles.headerDropSystemAudit}>
                          <Text type="body-2" weight={500}>
                            System Audit
                          </Text>
                          <UpgradePlan
                            text={`System Audit is available on Standard and Premium plans.`}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                <div className={styles.headerDropSection}>
                  <Text as="a" className="active" type="body-2" weight={500} onClick={logout}>
                    Log out
                  </Text>
                </div>
              </div>
            </Dropdown.Body>
          </Dropdown>
        </div>
      </div>
    </header>
  );
}

export default Header;
